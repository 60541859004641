import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import '../src/util/gt.js'
import { createRouter, createWebHistory } from 'vue-router'
// import * as fundebug from 'fundebug-javascript'
// import FundebugVue from 'fundebug-vue'
import { IndexAnchor, IndexBar, Popup, Cell, Overlay } from 'vant'

import('vant/lib/index.css')


// fundebug.init({
//   apikey: 'faccb944a6e5a58f2c2273483ed0f8a33142a994463441e8e8ce5b7c0f06a277',
//   silentResource: true,
//   silentConsole: true,
//   monitorHttpBody: true,
//   monitorHttpResponse: true,
//   silentDev: true,
//   appversion: '1.0.1',
//   filters: [
//     {
//       name: '/^Error/',
//       message: /'手机号不能为空'|'手机号格式不正确'|'验证码格式不正确'|'密码不能为空'|'验证码不能为空'/
//     },
//     {
//       req: {
//         method: /^POST$/
//       },
//       res: {
//         status: /^422$/
//       }
//     },
//     {
//       req: {
//         method: /^POST$/
//       },
//       res: {
//         status: /^429$/
//       }
//     }
//   ]
// })

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'index',
      component: import('../src/views/pc.vue')
    },
    {
      path: '/check',
      name: 'check',
      component: import('../src/views/check.vue')
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: import ('../src/views/terms/agreement.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: import ('../src/views/terms/privacy.vue')
    },
    {
      path: '/appEmbed',
      name: 'appEmbed',
      component: import ('../src/views/appEmbed.vue')
    }
  ]
})

const app = createApp(App)

app.use(router)
    .use(IndexAnchor)
    .use(IndexBar)
    .use(Popup)
    .use(Cell)
    .use(Overlay)
    .mount('#app')
