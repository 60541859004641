<script setup lang="ts">
  import { TencentCaptchaResult } from '../enums'
  import { tencentConfigApi } from '../api/login'

  const { VITE_TENCENT_CAPTCHA_APPID } = import.meta.env

  init()
  async function init(){
    const data  = await getAidEncrypted()
    await getTencentConfig(data)
  }

  async function getTencentConfig(id) {
    var captcha = new TencentCaptcha(VITE_TENCENT_CAPTCHA_APPID, callback, {
      aidEncrypted: id
    })
    // var captcha = new TencentCaptcha('193962325', callback, {});
    // 调用方法，显示验证码
    captcha.show()
  }

  async function getAidEncrypted() {
    const { data } = await tencentConfigApi()
    return data.encrypt_key
  }

  function callback(res) {
    jsToAppFunction(res)
  }
  function jsToAppFunction(res) {
    // 第一个参数传入回调结果，结果如下：
    // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
    // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
    // CaptchaAppId       String    验证码应用ID。
    // bizState    Any       自定义透传参数。
    // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
    // res（用户主动关闭验证码）= {ret: 2, ticket: null}
    // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
    // res（请求验证码发生错误，验证码自动返回trerror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
    if (checkDevice() === 'ios') {
      if (res.ret === TencentCaptchaResult.success) {
        window.webkit.messageHandlers.jsToAppWithPrams.postMessage({params: res})
      } else if (res.ret === TencentCaptchaResult.close) {
        window.webkit.messageHandlers.jsToAppNoPrams.postMessage(null)
      }
    } else {
      if (res.ret === TencentCaptchaResult.success) {
        Material.jsToAppWithPrams(JSON.stringify({ params: res }))
      } else if (res.ret === TencentCaptchaResult.close) {
        Material.jsToAppNoPrams()
      }
    }

  }

  function checkDevice() {
    const u = navigator.userAgent
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
    return isAndroid ? 'Android' : 'ios'
  }


</script>

<template>
  <div>
  </div>
</template>

<style scoped lang="less">

</style>
