export const countryList = [
  {
    "id": 101,
    "name": "中国",
    "first_letter": "Z",
    "iso_code": "CN",
    "idd_code": 86,
    "is_top": true,
    "is_foreign": false
  },
  {
    "id": 148,
    "name": "英国",
    "first_letter": "Y",
    "iso_code": "GB",
    "idd_code": 44,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 170,
    "name": "澳大利亚",
    "first_letter": "A",
    "iso_code": "AU",
    "idd_code": 61,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 167,
    "name": "美国",
    "first_letter": "M",
    "iso_code": "US",
    "idd_code": 1,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 75,
    "name": "中国香港",
    "first_letter": "Z",
    "iso_code": "HK",
    "idd_code": 852,
    "is_top": true,
    "is_foreign": false
  },
  {
    "id": 166,
    "name": "加拿大",
    "first_letter": "J",
    "iso_code": "CA",
    "idd_code": 1,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 57,
    "name": "新加坡",
    "first_letter": "X",
    "iso_code": "SG",
    "idd_code": 65,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 182,
    "name": "新西兰",
    "first_letter": "X",
    "iso_code": "NZ",
    "idd_code": 64,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 150,
    "name": "法国",
    "first_letter": "F",
    "iso_code": "FR",
    "idd_code": 33,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 155,
    "name": "西班牙",
    "first_letter": "X",
    "iso_code": "ES",
    "idd_code": 34,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 144,
    "name": "爱尔兰",
    "first_letter": "A",
    "iso_code": "IE",
    "idd_code": 353,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 62,
    "name": "日本",
    "first_letter": "R",
    "iso_code": "JP",
    "idd_code": 81,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 66,
    "name": "泰国",
    "first_letter": "T",
    "iso_code": "TH",
    "idd_code": 66,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 87,
    "name": "马来西亚",
    "first_letter": "M",
    "iso_code": "MY",
    "idd_code": 60,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 99,
    "name": "菲律宾",
    "first_letter": "F",
    "iso_code": "PH",
    "idd_code": 63,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 100,
    "name": "中国台湾",
    "first_letter": "Z",
    "iso_code": "TW",
    "idd_code": 886,
    "is_top": true,
    "is_foreign": false
  },
  {
    "id": 71,
    "name": "中国澳门",
    "first_letter": "Z",
    "iso_code": "MO",
    "idd_code": 853,
    "is_top": true,
    "is_foreign": false
  },
  {
    "id": 149,
    "name": "德国",
    "first_letter": "D",
    "iso_code": "DE",
    "idd_code": 49,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 58,
    "name": "韩国",
    "first_letter": "H",
    "iso_code": "KR",
    "idd_code": 82,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 141,
    "name": "荷兰",
    "first_letter": "H",
    "iso_code": "NL",
    "idd_code": 31,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 128,
    "name": "瑞典",
    "first_letter": "R",
    "iso_code": "SE",
    "idd_code": 46,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 129,
    "name": "瑞士",
    "first_letter": "R",
    "iso_code": "CH",
    "idd_code": 41,
    "is_top": true,
    "is_foreign": true
  },
  {
    "id": 157,
    "name": "安道尔",
    "first_letter": "A",
    "iso_code": "AD",
    "idd_code": 376,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 160,
    "name": "奥地利",
    "first_letter": "A",
    "iso_code": "AT",
    "idd_code": 43,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 156,
    "name": "阿尔巴尼亚",
    "first_letter": "A",
    "iso_code": "AL",
    "idd_code": 355,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 51,
    "name": "阿尔及利亚",
    "first_letter": "A",
    "iso_code": "DZ",
    "idd_code": 213,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 70,
    "name": "阿富汗",
    "first_letter": "A",
    "iso_code": "AF",
    "idd_code": 93,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 12,
    "name": "安哥拉",
    "first_letter": "A",
    "iso_code": "AO",
    "idd_code": 244,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 208,
    "name": "阿根廷",
    "first_letter": "A",
    "iso_code": "AR",
    "idd_code": 54,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 7,
    "name": "埃及",
    "first_letter": "A",
    "iso_code": "EG",
    "idd_code": 20,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 196,
    "name": "阿鲁巴岛",
    "first_letter": "A",
    "iso_code": "AW",
    "idd_code": 297,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 68,
    "name": "阿联酋",
    "first_letter": "A",
    "iso_code": "AE",
    "idd_code": 971,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 84,
    "name": "阿曼",
    "first_letter": "A",
    "iso_code": "OM",
    "idd_code": 968,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 117,
    "name": "阿塞拜疆",
    "first_letter": "A",
    "iso_code": "AZ",
    "idd_code": 994,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 45,
    "name": "埃塞俄比亚",
    "first_letter": "A",
    "iso_code": "ET",
    "idd_code": 251,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 130,
    "name": "爱沙尼亚",
    "first_letter": "A",
    "iso_code": "EE",
    "idd_code": 372,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 195,
    "name": "安提瓜和巴布达",
    "first_letter": "A",
    "iso_code": "AG",
    "idd_code": 1268,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 198,
    "name": "巴巴多斯",
    "first_letter": "B",
    "iso_code": "BB",
    "idd_code": 1246,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 184,
    "name": "巴布亚新几内亚",
    "first_letter": "B",
    "iso_code": "PG",
    "idd_code": 675,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 28,
    "name": "博茨瓦纳",
    "first_letter": "B",
    "iso_code": "BW",
    "idd_code": 267,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 90,
    "name": "不丹",
    "first_letter": "B",
    "iso_code": "BT",
    "idd_code": 975,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 122,
    "name": "冰岛",
    "first_letter": "B",
    "iso_code": "IS",
    "idd_code": 354,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 111,
    "name": "波多黎各",
    "first_letter": "B",
    "iso_code": "PR",
    "idd_code": 1787,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 118,
    "name": "白俄罗斯",
    "first_letter": "B",
    "iso_code": "BY",
    "idd_code": 375,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 197,
    "name": "巴哈马",
    "first_letter": "B",
    "iso_code": "BS",
    "idd_code": 1242,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 161,
    "name": "保加利亚",
    "first_letter": "B",
    "iso_code": "BG",
    "idd_code": 359,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 9,
    "name": "布基纳法索",
    "first_letter": "B",
    "iso_code": "BF",
    "idd_code": 226,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 92,
    "name": "巴基斯坦",
    "first_letter": "B",
    "iso_code": "PK",
    "idd_code": 92,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 61,
    "name": "巴林",
    "first_letter": "B",
    "iso_code": "BH",
    "idd_code": 973,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 154,
    "name": "波兰",
    "first_letter": "B",
    "iso_code": "PL",
    "idd_code": 48,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 190,
    "name": "秘鲁",
    "first_letter": "B",
    "iso_code": "PE",
    "idd_code": 51,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 50,
    "name": "布隆迪",
    "first_letter": "B",
    "iso_code": "BI",
    "idd_code": 257,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 189,
    "name": "巴拉圭",
    "first_letter": "B",
    "iso_code": "PY",
    "idd_code": 595,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 145,
    "name": "比利时",
    "first_letter": "B",
    "iso_code": "BE",
    "idd_code": 32,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 210,
    "name": "玻利维亚",
    "first_letter": "B",
    "iso_code": "BO",
    "idd_code": 591,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 209,
    "name": "伯利兹",
    "first_letter": "B",
    "iso_code": "BZ",
    "idd_code": 501,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 165,
    "name": "百慕大",
    "first_letter": "B",
    "iso_code": "BM",
    "idd_code": 1441,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 2,
    "name": "贝宁",
    "first_letter": "B",
    "iso_code": "BJ",
    "idd_code": 229,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 108,
    "name": "巴拿马",
    "first_letter": "B",
    "iso_code": "PA",
    "idd_code": 507,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 139,
    "name": "波斯尼亚和黑塞哥维那",
    "first_letter": "B",
    "iso_code": "BA",
    "idd_code": 387,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 211,
    "name": "巴西",
    "first_letter": "B",
    "iso_code": "BR",
    "idd_code": 55,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 54,
    "name": "赤道几内亚",
    "first_letter": "C",
    "iso_code": "GQ",
    "idd_code": 240,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 73,
    "name": "朝鲜",
    "first_letter": "C",
    "iso_code": "KP",
    "idd_code": 850,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 42,
    "name": "多哥",
    "first_letter": "D",
    "iso_code": "TG",
    "idd_code": 228,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 147,
    "name": "丹麦",
    "first_letter": "D",
    "iso_code": "DK",
    "idd_code": 45,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 105,
    "name": "多米尼加",
    "first_letter": "D",
    "iso_code": "DM",
    "idd_code": 1767,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 164,
    "name": "多米尼克",
    "first_letter": "D",
    "iso_code": "DM",
    "idd_code": 1767,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 194,
    "name": "厄瓜多尔",
    "first_letter": "E",
    "iso_code": "EC",
    "idd_code": 593,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 119,
    "name": "俄罗斯",
    "first_letter": "E",
    "iso_code": "RU",
    "idd_code": 70,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 10,
    "name": "厄立特里亚",
    "first_letter": "E",
    "iso_code": "ER",
    "idd_code": 291,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 140,
    "name": "梵蒂冈城国",
    "first_letter": "F",
    "iso_code": "VA",
    "idd_code": 39,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 49,
    "name": "佛得角",
    "first_letter": "F",
    "iso_code": "CV",
    "idd_code": 238,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 183,
    "name": "斐济",
    "first_letter": "F",
    "iso_code": "FJ",
    "idd_code": 679,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 162,
    "name": "芬兰",
    "first_letter": "F",
    "iso_code": "FI",
    "idd_code": 358,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 181,
    "name": "法属玻利尼西亚",
    "first_letter": "F",
    "iso_code": "PF",
    "idd_code": 689,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 104,
    "name": "古巴",
    "first_letter": "G",
    "iso_code": "CU",
    "idd_code": 53,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 53,
    "name": "冈比亚",
    "first_letter": "G",
    "iso_code": "GM",
    "idd_code": 220,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 35,
    "name": "刚果",
    "first_letter": "G",
    "iso_code": "CG",
    "idd_code": 242,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 187,
    "name": "哥伦比亚",
    "first_letter": "G",
    "iso_code": "CO",
    "idd_code": 57,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 115,
    "name": "格鲁吉亚",
    "first_letter": "G",
    "iso_code": "GE",
    "idd_code": 995,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 168,
    "name": "格陵兰",
    "first_letter": "G",
    "iso_code": "GL",
    "idd_code": 299,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 200,
    "name": "格林纳达",
    "first_letter": "G",
    "iso_code": "GD",
    "idd_code": 1473,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 103,
    "name": "哥斯达黎加",
    "first_letter": "G",
    "iso_code": "CR",
    "idd_code": 506,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 188,
    "name": "圭亚那",
    "first_letter": "G",
    "iso_code": "GY",
    "idd_code": 592,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 201,
    "name": "海地",
    "first_letter": "H",
    "iso_code": "HT",
    "idd_code": 509,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 113,
    "name": "洪都拉斯",
    "first_letter": "H",
    "iso_code": "HN",
    "idd_code": 504,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 109,
    "name": "荷属安地列斯群岛",
    "first_letter": "H",
    "iso_code": "AN",
    "idd_code": 599,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 98,
    "name": "哈萨克斯坦",
    "first_letter": "H",
    "iso_code": "KZ",
    "idd_code": 7,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 14,
    "name": "津巴布韦",
    "first_letter": "J",
    "iso_code": "ZW",
    "idd_code": 263,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 52,
    "name": "吉布提",
    "first_letter": "J",
    "iso_code": "DJ",
    "idd_code": 253,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 65,
    "name": "吉尔吉斯斯坦",
    "first_letter": "J",
    "iso_code": "KG",
    "idd_code": 996,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 136,
    "name": "捷克",
    "first_letter": "J",
    "iso_code": "CZ",
    "idd_code": 420,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 180,
    "name": "基里巴斯",
    "first_letter": "J",
    "iso_code": "KI",
    "idd_code": 686,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 19,
    "name": "加纳",
    "first_letter": "J",
    "iso_code": "GH",
    "idd_code": 233,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 15,
    "name": "几内亚",
    "first_letter": "J",
    "iso_code": "GN",
    "idd_code": 224,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 31,
    "name": "几内亚比绍",
    "first_letter": "J",
    "iso_code": "GW",
    "idd_code": 245,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 18,
    "name": "加蓬",
    "first_letter": "J",
    "iso_code": "GA",
    "idd_code": 241,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 91,
    "name": "柬埔寨",
    "first_letter": "J",
    "iso_code": "KH",
    "idd_code": 855,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 171,
    "name": "库克群岛",
    "first_letter": "K",
    "iso_code": "CK",
    "idd_code": 682,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 142,
    "name": "克罗地亚",
    "first_letter": "K",
    "iso_code": "HR",
    "idd_code": 385,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 1,
    "name": "喀麦隆",
    "first_letter": "K",
    "iso_code": "CM",
    "idd_code": 237,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 27,
    "name": "科摩罗",
    "first_letter": "K",
    "iso_code": "KM",
    "idd_code": 269,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 199,
    "name": "开曼群岛",
    "first_letter": "K",
    "iso_code": "KY",
    "idd_code": 1345,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 56,
    "name": "肯尼亚",
    "first_letter": "K",
    "iso_code": "KE",
    "idd_code": 254,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 6,
    "name": "科特迪瓦",
    "first_letter": "K",
    "iso_code": "CI",
    "idd_code": 225,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 96,
    "name": "卡塔尔",
    "first_letter": "K",
    "iso_code": "QA",
    "idd_code": 974,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 78,
    "name": "科威特",
    "first_letter": "K",
    "iso_code": "KW",
    "idd_code": 965,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 37,
    "name": "利比里亚",
    "first_letter": "L",
    "iso_code": "LR",
    "idd_code": 231,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 77,
    "name": "黎巴嫩",
    "first_letter": "L",
    "iso_code": "LB",
    "idd_code": 961,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 13,
    "name": "利比亚",
    "first_letter": "L",
    "iso_code": "LY",
    "idd_code": 218,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 126,
    "name": "罗马尼亚",
    "first_letter": "L",
    "iso_code": "RO",
    "idd_code": 40,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 17,
    "name": "留尼汪",
    "first_letter": "L",
    "iso_code": "RE",
    "idd_code": 262,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 152,
    "name": "卢森堡",
    "first_letter": "L",
    "iso_code": "LU",
    "idd_code": 352,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 34,
    "name": "莱索托",
    "first_letter": "L",
    "iso_code": "LS",
    "idd_code": 266,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 132,
    "name": "立陶宛",
    "first_letter": "L",
    "iso_code": "LT",
    "idd_code": 370,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 131,
    "name": "拉脱维亚",
    "first_letter": "L",
    "iso_code": "LV",
    "idd_code": 371,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 69,
    "name": "老挝",
    "first_letter": "L",
    "iso_code": "LA",
    "idd_code": 856,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 4,
    "name": "卢旺达",
    "first_letter": "L",
    "iso_code": "RW",
    "idd_code": 250,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 158,
    "name": "列支敦士登",
    "first_letter": "L",
    "iso_code": "LI",
    "idd_code": 423,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 86,
    "name": "缅甸",
    "first_letter": "M",
    "iso_code": "MM",
    "idd_code": 95,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 3,
    "name": "马达加斯加",
    "first_letter": "M",
    "iso_code": "MG",
    "idd_code": 261,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 80,
    "name": "马尔代夫",
    "first_letter": "M",
    "iso_code": "MV",
    "idd_code": 960,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 133,
    "name": "摩尔多瓦",
    "first_letter": "M",
    "iso_code": "MD",
    "idd_code": 373,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 123,
    "name": "马耳他",
    "first_letter": "M",
    "iso_code": "MT",
    "idd_code": 356,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 83,
    "name": "蒙古",
    "first_letter": "M",
    "iso_code": "MN",
    "idd_code": 976,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 93,
    "name": "孟加拉国",
    "first_letter": "M",
    "iso_code": "BD",
    "idd_code": 880,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 178,
    "name": "密克罗尼西亚联邦",
    "first_letter": "M",
    "iso_code": "FM",
    "idd_code": 691,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 21,
    "name": "马里",
    "first_letter": "M",
    "iso_code": "ML",
    "idd_code": 223,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 46,
    "name": "摩洛哥",
    "first_letter": "M",
    "iso_code": "MA",
    "idd_code": 212,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 8,
    "name": "毛里求斯",
    "first_letter": "M",
    "iso_code": "MU",
    "idd_code": 230,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 23,
    "name": "毛里塔尼亚",
    "first_letter": "M",
    "iso_code": "MR",
    "idd_code": 222,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 47,
    "name": "马拉维",
    "first_letter": "M",
    "iso_code": "MW",
    "idd_code": 265,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 124,
    "name": "摩纳哥",
    "first_letter": "M",
    "iso_code": "MC",
    "idd_code": 377,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 138,
    "name": "马其顿",
    "first_letter": "M",
    "iso_code": "MK",
    "idd_code": 389,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 44,
    "name": "莫桑比克",
    "first_letter": "M",
    "iso_code": "MZ",
    "idd_code": 258,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 179,
    "name": "马绍尔群岛",
    "first_letter": "M",
    "iso_code": "MH",
    "idd_code": 692,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 203,
    "name": "马提尼克",
    "first_letter": "M",
    "iso_code": "MQ",
    "idd_code": 596,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 204,
    "name": "蒙特塞拉特",
    "first_letter": "M",
    "iso_code": "MS",
    "idd_code": 1664,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 106,
    "name": "墨西哥",
    "first_letter": "M",
    "iso_code": "MX",
    "idd_code": 52,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 32,
    "name": "民主刚果",
    "first_letter": "M",
    "iso_code": "CD",
    "idd_code": 243,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 97,
    "name": "尼泊尔",
    "first_letter": "N",
    "iso_code": "NP",
    "idd_code": 977,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 36,
    "name": "南非",
    "first_letter": "N",
    "iso_code": "ZA",
    "idd_code": 27,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 107,
    "name": "尼加拉瓜",
    "first_letter": "N",
    "iso_code": "NI",
    "idd_code": 505,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 172,
    "name": "瑙鲁",
    "first_letter": "N",
    "iso_code": "NR",
    "idd_code": 674,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 43,
    "name": "纳米比亚",
    "first_letter": "N",
    "iso_code": "NA",
    "idd_code": 264,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 40,
    "name": "尼日尔",
    "first_letter": "N",
    "iso_code": "NE",
    "idd_code": 227,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 48,
    "name": "尼日利亚",
    "first_letter": "N",
    "iso_code": "NG",
    "idd_code": 234,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 125,
    "name": "挪威",
    "first_letter": "N",
    "iso_code": "NO",
    "idd_code": 47,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 185,
    "name": "帕劳",
    "first_letter": "P",
    "iso_code": "PW",
    "idd_code": 680,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 153,
    "name": "葡萄牙",
    "first_letter": "P",
    "iso_code": "PT",
    "idd_code": 351,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 55,
    "name": "苏丹",
    "first_letter": "S",
    "iso_code": "SD",
    "idd_code": 249,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 11,
    "name": "圣多美和普林西比",
    "first_letter": "S",
    "iso_code": "ST",
    "idd_code": 239,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 110,
    "name": "萨尔瓦多",
    "first_letter": "S",
    "iso_code": "SV",
    "idd_code": 503,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 137,
    "name": "斯洛伐克",
    "first_letter": "S",
    "iso_code": "SK",
    "idd_code": 421,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 16,
    "name": "塞拉利昂",
    "first_letter": "S",
    "iso_code": "SL",
    "idd_code": 232,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 67,
    "name": "斯里兰卡",
    "first_letter": "S",
    "iso_code": "LK",
    "idd_code": 94,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 175,
    "name": "所罗门群岛",
    "first_letter": "S",
    "iso_code": "SB",
    "idd_code": 677,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 191,
    "name": "苏里南",
    "first_letter": "S",
    "iso_code": "SR",
    "idd_code": 597,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 135,
    "name": "斯洛文尼亚",
    "first_letter": "S",
    "iso_code": "SI",
    "idd_code": 386,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 22,
    "name": "索马里",
    "first_letter": "S",
    "iso_code": "SO",
    "idd_code": 252,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 127,
    "name": "圣马力诺",
    "first_letter": "S",
    "iso_code": "SM",
    "idd_code": 378,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 176,
    "name": "萨摩亚",
    "first_letter": "S",
    "iso_code": "WS",
    "idd_code": 684,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 29,
    "name": "塞内加尔",
    "first_letter": "S",
    "iso_code": "SN",
    "idd_code": 221,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 207,
    "name": "圣皮埃尔和密克隆",
    "first_letter": "S",
    "iso_code": "PM",
    "idd_code": 508,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 146,
    "name": "塞浦路斯",
    "first_letter": "S",
    "iso_code": "CY",
    "idd_code": 357,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 206,
    "name": "圣其茨-尼维斯",
    "first_letter": "S",
    "iso_code": "KN",
    "idd_code": 1869,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 5,
    "name": "塞舌尔",
    "first_letter": "S",
    "iso_code": "SC",
    "idd_code": 248,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 94,
    "name": "沙特阿拉伯",
    "first_letter": "S",
    "iso_code": "SA",
    "idd_code": 966,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 30,
    "name": "斯威士兰",
    "first_letter": "S",
    "iso_code": "SZ",
    "idd_code": 268,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 112,
    "name": "圣文森特和格纳丁斯",
    "first_letter": "S",
    "iso_code": "VC",
    "idd_code": 1784,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 134,
    "name": "土耳其",
    "first_letter": "T",
    "iso_code": "TR",
    "idd_code": 90,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 169,
    "name": "汤加",
    "first_letter": "T",
    "iso_code": "TO",
    "idd_code": 676,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 72,
    "name": "塔吉克斯坦",
    "first_letter": "T",
    "iso_code": "TJ",
    "idd_code": 992,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 95,
    "name": "土库曼斯坦",
    "first_letter": "T",
    "iso_code": "TM",
    "idd_code": 7370,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 205,
    "name": "特立尼达和多巴哥",
    "first_letter": "T",
    "iso_code": "TT",
    "idd_code": 1868,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 38,
    "name": "突尼斯",
    "first_letter": "T",
    "iso_code": "TN",
    "idd_code": 216,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 20,
    "name": "坦桑尼亚",
    "first_letter": "T",
    "iso_code": "TZ",
    "idd_code": 255,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 177,
    "name": "图瓦卢",
    "first_letter": "T",
    "iso_code": "TV",
    "idd_code": 688,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 114,
    "name": "危地马拉",
    "first_letter": "W",
    "iso_code": "GT",
    "idd_code": 502,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 24,
    "name": "乌干达",
    "first_letter": "W",
    "iso_code": "UG",
    "idd_code": 256,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 120,
    "name": "乌克兰",
    "first_letter": "W",
    "iso_code": "UA",
    "idd_code": 380,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 79,
    "name": "文莱",
    "first_letter": "W",
    "iso_code": "BN",
    "idd_code": 673,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 193,
    "name": "乌拉圭",
    "first_letter": "W",
    "iso_code": "UY",
    "idd_code": 598,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 174,
    "name": "瓦努阿图",
    "first_letter": "W",
    "iso_code": "VU",
    "idd_code": 678,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 192,
    "name": "委内瑞拉",
    "first_letter": "W",
    "iso_code": "VE",
    "idd_code": 58,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 60,
    "name": "乌兹别克斯坦",
    "first_letter": "W",
    "iso_code": "UZ",
    "idd_code": 998,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 173,
    "name": "新喀里多尼亚",
    "first_letter": "X",
    "iso_code": "NC",
    "idd_code": 687,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 143,
    "name": "希腊",
    "first_letter": "X",
    "iso_code": "GR",
    "idd_code": 30,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 59,
    "name": "叙利亚",
    "first_letter": "X",
    "iso_code": "SY",
    "idd_code": 963,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 41,
    "name": "西撒哈拉",
    "first_letter": "X",
    "iso_code": "EH",
    "idd_code": 212,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 121,
    "name": "匈牙利",
    "first_letter": "X",
    "iso_code": "HU",
    "idd_code": 36,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 63,
    "name": "约旦",
    "first_letter": "Y",
    "iso_code": "JO",
    "idd_code": 962,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 85,
    "name": "印度",
    "first_letter": "Y",
    "iso_code": "IN",
    "idd_code": 91,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 151,
    "name": "意大利",
    "first_letter": "Y",
    "iso_code": "IT",
    "idd_code": 39,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 81,
    "name": "印度尼西亚",
    "first_letter": "Y",
    "iso_code": "ID",
    "idd_code": 62,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 102,
    "name": "伊朗",
    "first_letter": "Y",
    "iso_code": "IR",
    "idd_code": 98,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 76,
    "name": "伊拉克",
    "first_letter": "Y",
    "iso_code": "IQ",
    "idd_code": 964,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 89,
    "name": "也门",
    "first_letter": "Y",
    "iso_code": "YE",
    "idd_code": 967,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 202,
    "name": "牙买加",
    "first_letter": "Y",
    "iso_code": "JM",
    "idd_code": 1876,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 116,
    "name": "亚美尼亚",
    "first_letter": "Y",
    "iso_code": "AM",
    "idd_code": 374,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 64,
    "name": "越南",
    "first_letter": "Y",
    "iso_code": "VN",
    "idd_code": 84,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 82,
    "name": "以色列",
    "first_letter": "Y",
    "iso_code": "IL",
    "idd_code": 972,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 163,
    "name": "直布罗陀",
    "first_letter": "Z",
    "iso_code": "GI",
    "idd_code": 350,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 39,
    "name": "赞比亚",
    "first_letter": "Z",
    "iso_code": "ZM",
    "idd_code": 260,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 25,
    "name": "乍得",
    "first_letter": "Z",
    "iso_code": "TD",
    "idd_code": 235,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 33,
    "name": "中非",
    "first_letter": "Z",
    "iso_code": "CF",
    "idd_code": 236,
    "is_top": false,
    "is_foreign": true
  },
  {
    "id": 186,
    "name": "智利",
    "first_letter": "Z",
    "iso_code": "CL",
    "idd_code": 56,
    "is_top": false,
    "is_foreign": true
  }
]
