enum ELoginType {
  'captcha',
  'password',
  'forgot',
  'bind' = 3
}

enum TencentCaptchaResult {
  success = 0,
  close=2
}

export {ELoginType, TencentCaptchaResult}
