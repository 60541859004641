<script setup lang="ts">
  import { checkDevice } from './util/utils'
  import PcLogin from './views/pc.vue'
  import H5Login from './views/h5.vue'
  import Check from './views/check.vue'
  import Agreement from './views/terms/agreement.vue'
  import Privacy from './views/terms/privacy.vue'
  import AppEmbed from './views/appEmbed.vue'

  const platform = checkDevice()
</script>

<template>
  <template v-if="platform === 'pc'">
    <agreement v-if="$route.path.indexOf('agreement')>-1"></agreement>
    <pc-login v-if="$route.name==='index'"></pc-login>
    <privacy v-if="$route.path.indexOf('privacy')>-1"></privacy>
    <app-embed v-if="$route.name === 'appEmbed'"></app-embed>

  </template>
  <template v-if="platform === 'h5'">
    <check v-if="$route.path.indexOf('check')>-1"></check>
    <h5-login v-if="$route.name==='index'"></h5-login>
    <app-embed v-if="$route.name === 'appEmbed'"></app-embed>
  </template>
</template>

<style scoped>
  input, textarea {
    font-size: 14px; /* 设置输入框字体大小 */
    -webkit-text-size-adjust: 100%; /* 禁用自动缩放 */
  }
</style>
