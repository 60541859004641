import { ref } from 'vue'

export function usePassword() {

  const firstPasswordInput = ref<boolean>(true)
  const password = ref<string>('')
  const passwordMessage = ref<string>('')
  const passwordInputType = ref<string>('password')

  async function onPasswordChange() {
    try {
      firstPasswordInput.value = false
      await checkPassword(password.value)
      passwordMessage.value = '密码不能为空'
    } catch (e) {
      passwordMessage.value = e as string
      throw Error(e)
    }
  }

  function checkPassword(password: string | undefined) {
    // const regPassword = /^(?=.*[0-9])(?=.*[a-zA-Z!@_#$%^&*()\-+=,.?]).{8,16}$/
    return new Promise((resolve, reject) => {
      if (!password) return reject('密码不能为空')
      if(password.length===0){
        reject('密码不能为空')
      }else{
        resolve('')
      }
      // if (!regPassword.test(password)) {
      //   reject('密码长度不能小于8位必须包含数字，且必须包含字母或其它符号')
      // } else {
      //   resolve('')
      // }

    })
  }

  function handleChangePasswordInputType() {
    passwordInputType.value = passwordInputType.value === 'password' ? 'text' : 'password'
  }

  return {
    firstPasswordInput,
    password,
    passwordMessage,
    passwordInputType,
    onPasswordChange,
    checkPassword,
    handleChangePasswordInputType
  }
}
