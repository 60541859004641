import Request from '../util/httpRequest'
import {
  IGeetestConfigResponse,
  ILoginCodeParams,
  ILoginParam,
  ILoginResponse,
  IHttpResult
} from '../types'
// 账号密码登录
const loginApi = (data: ILoginParam): IHttpResult<ILoginResponse> => {
  return Request('login', 'post', data, 'v1')
}

// 获取极验配置
const geetest = (): IHttpResult<IGeetestConfigResponse> => {
  return Request(`geetest/config/web`, 'get', {}, 'v1')
}
//
// 获取登录验证码
const getLoginCode = (data: ILoginCodeParams) => {
  return Request(`captcha/login/web`, 'post', data, 'v1')
}
//
// 验证码登录
const captchaLogin = (data: ILoginParam) => {
  return Request('login/captcha', 'post', data, 'v1')
}

// 获取重置密码验证码
const getForgotCode = (data: ILoginCodeParams) => {
  return Request(`captcha/forgot_password`, 'post', data, 'v2')
}
//
// 忘记密码
const forgot = (data: ILoginParam): IHttpResult<ILoginResponse> => {
  return Request('forgot/password', 'post', data, 'v1')
}
//
// 个人信息
const personal = () => {
  return Request('me', 'get', {}, 'v1')
}
//
// // 国家列表
const countryListApi = () => {
  return Request('countries', 'get', {}, 'v1')
}

// 微信授权登录
const wechat = (data: {}) => {
  return Request('login/wechat', 'post', data, 'v1')
}

// 微信绑定
const weChatBind = (data) => {
  return Request('bind/wechat', 'post', data, 'v1')
}


//微信取消绑定
const weChatUnBind = (data) => {
  return Request('unbind/wechat', 'post', data, 'v1')
}


const  tencentCodeApi = (data) => {
  return Request('captcha/login', 'POST', data, 'v2')
}

const tencentConfigApi = () => {
  return Request('tencent/captcha/config', 'GET', {}, 'v1')
}

export {
  loginApi,
  countryListApi,
  geetest,
  getForgotCode,
  getLoginCode,
  forgot,
  captchaLogin,
  personal,
  wechat,
  weChatUnBind, weChatBind, tencentCodeApi,
  tencentConfigApi
}
