import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosPromise,
  AxiosRequestHeaders
} from 'axios'
import { ElMessage } from 'element-plus'
import { Md5 } from 'ts-md5'
import Qs from 'qs'
import { INormalObject } from '../types'

const needEncryptionApiArr = [
  'v1/login',
  'v1/wechat/mini_program/scheme',
  'v1/me/get_token',
  'v1/me/avatar',
  'v1/captcha/login/web',
  'v1/captcha/forgot_password/web'
]
import { useLogin } from '../hooks/useLogin'
import { checkDevice } from './utils'
import * as process from 'node:process'

function sortParams(target: INormalObject, md5Str: string) {
  let newMd5Str = md5Str
  const sortTarget: INormalObject = {}
  const sortKeys = Object.keys(target).sort()
  sortKeys.forEach((key, index) => {
    sortTarget[key] = encodeURIComponent(target[key])
    if (index === sortKeys.length - 1) {
      newMd5Str += `${key}=${encodeURIComponent(
          target[key]
      )}3c4c4a76abdfe223fe9bebc2fd1ddb71`
    } else {
      newMd5Str += `${key}=${encodeURIComponent(target[key])}&`
    }
  })
  return newMd5Str
}

const { VITE_BASE_URL } = import.meta.env
// const VITE_BASE_URL = process.env.ZAE_PROXY_URL
const request = axios.create({
  baseURL: VITE_BASE_URL,
  timeout: 5000,
  withCredentials: true // 跨域请求时需要凭证
})

function findAppName() {
  const redirectUrl = window.location.search
  if (redirectUrl) {
    const url = new URL(redirectUrl.split('?')[1].split('=')[1])
    if (url.host.indexOf('toefl') > -1) {
      return 'papatoefl'
    } else if (url.host.indexOf('ielts') > -1) {
      return 'papaielts'
    } else {
      return 'papaedu'
    }
  }
}

const headers: AxiosRequestHeaders = {
  platform: checkDevice() === 'h5' ? 'wap' : 'web',
  version: '1.0.0',
  'app-name': findAppName(),
  timestamp: 0,
  sign: ''
}
request.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        headers.timestamp = Math.ceil(new Date().getTime() / 1000)
        let md5Str = ''
        if (config.data) {
          const target = {
            ...headers,
            ...config.data,
            path: config.url
          }
          delete target.openid
          delete target.authorization
          delete target.sign
          md5Str = sortParams(target, md5Str)
        } else if (config.params) {
          const target = {
            ...headers,
            ...config.params,
            path: config.url
          }
          delete target.openid
          delete target.authorization
          delete target.sign
          md5Str = sortParams(target, md5Str)
        } else {
          const target = {
            ...headers,
            path: config.url
          }
          delete target.authorization
          delete target.sign
          md5Str = sortParams(target, md5Str)
        }
        const sign = Md5.hashStr(md5Str)
        headers.sign = sign
      headers.sign = sign

      const userStore = useLogin()
      if (userStore?.authorization?.access_token) {
        headers.authorization = `${userStore.authorization.token_type} ${userStore.authorization.access_token}`
      } else {
        delete config.headers?.authorization
        delete headers.authorization
      }

      config.data = Qs.stringify(config.data, { arrayFormat: 'brackets' })
      config.headers = config.headers || {}
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.headers = {
        ...config.headers,
        ...headers
      }
      return config
    },
    error => {
      return Promise.resolve(error)
    }
)

request.interceptors.response.use(
    (response: AxiosResponse) => {
      const theResponse = {
        data: response.data.data,
        status: response.status,
        pagination: {}
      }
      if (response.data.links) {
        theResponse.pagination = {
          ...response.data.links,
          ...response.data.meta
        }
      }
      return theResponse
    },
    error => {
      const { status } = error.response
      switch (status) {
        case 400:
          ElMessage({
            message: error.response.data.message,
            type: 'error',
            grouping: true
          })
          break
        case 401:
          break
        case 403:
          ElMessage({
            message: error.response.data.message,
            type: 'error',
            grouping: true
          })
          break
        case 404:
          ElMessage({
            message: '请求的接口不存在',
            type: 'error',
            grouping: true
          })
          break
        case 406:
          // Message.error('权限不足，无法执行该操作')
          break
        case 422:
          ElMessage.error(error.response.data.message)

          break
        case 423:
          break
        case 429:
          ElMessage.error('手速太快了， 趴趴都跟不上了~')
          break
        case 500:
          ElMessage.error(error.response.data.message)
          break
        case 503:
          break
        default:
          break
      }
      return Promise.reject(error.response)
    }
)

interface IOptions {
  url: string
  method: 'get' | 'post' | 'put' | 'delete'
  params?: any
  data?: any
}

function httpRequest(
    url: string,
    method: IOptions['method'],
    data?: object,
    apiLevel = 'v2/exam/toefl'
): AxiosPromise {
  const options: IOptions = {
    url: `${apiLevel}/${url}`,
    method
  }
  options[method === ('get' || 'delete') ? 'params' : 'data'] = data
  return request(options)
}

export default httpRequest
