import { ref } from 'vue'
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { checkDevice } from '../util/utils'


export function useUserFormItem() {
  const firstUsernameInput = ref<boolean>(true)
  const username = ref<string>('')
  const usernameMessage = ref<string>('')
  const isoCode = ref<CountryCode>('CN')

  async function onUsernameChange() {
    try {
      firstUsernameInput.value = false
      username.value = username.value.slice(0, 11)
      usernameMessage.value = ''
    } catch (error) {
      throw Error(error as string)
    }
  }

  function checkUsername() {
    if (!username.value) {
      firstUsernameInput.value = false
      if(checkDevice() === 'pc'){
        usernameMessage.value = ''
      }else{
        usernameMessage.value = '手机号不能为空'
      }
      throw Error('手机号不能为空' as string)
    }
    if(isoCode.value === 'CN'){
      if (/^1[3456789]\d{9}$/.test(username.value) || /^0000\d{7}/.test(username.value)) {
        return usernameMessage.value = ''
      } else {
        usernameMessage.value = '手机号格式不正确'
        throw Error('手机号格式不正确' as string)

      }
    }else{
      const phoneNumberValidate = parsePhoneNumber(username.value, isoCode.value)
      if (phoneNumberValidate?.isValid()) {
        return usernameMessage.value = ''
      } else {
        usernameMessage.value = '手机号格式不正确'
        throw Error('手机号格式不正确' as string)
      }
    }


  }

  function getIsoCode(code: CountryCode) {
    isoCode.value = code
  }

  return {
    firstUsernameInput,
    username,
    usernameMessage,
    checkUsername,
    onUsernameChange,
    getIsoCode
  }
}
