<template>
  <div class="wrap">
    <p class="headline">趴趴{{ ieltsRes }}隐私政策</p>
    <p>第 5 版本</p>
    <p>更新日期：2023年04月06日</p>
    <p>生效日期：2018年10月20日</p>
    <!-- <p class="fontWeight">本隐私政策将帮助你了解：</p> -->
    <p>
      欢迎使用“趴趴{{ieltsRes}}”提供的产品和服务！作为趴趴{{ieltsRes}}的运营者，上海茵朗信息科技有限公司（或简称<span class="special"
        >“我们”</span
      >，联系方式见本隐私政策第九条）深知个人信息对您的重要性，我们一向庄严承诺保护使用我们的产品和服务（以下统称<span class="special">“趴趴{{ieltsRes}}服务”</span>）之用户（以下统称<span class="special">“用户”</span>或<span class="special">“您”</span>）的个人信息及隐私安全。您在使用趴趴{{ieltsRes}}服务时，我们可能会收集和使用您的相关个人信息（或简称<span class="special">“个人信息”</span>）。《趴趴{{ieltsRes}}隐私政策》（以下简称<span class="special">“本政策”</span>）适用于我们提供的趴趴{{ieltsRes}}服务， 我们希望通过本政策向您说明我们在收集和使用您相关个人信息时对应的处理规则，以及我们为您提供的访问、更正、删除和保护这些个人信息的方式，以便更好的保障您的权益。
    </p>
    <p>
      本政策将帮助您了解以下内容：
    </p>
    <p>
      一、我们如何收集和使用您的个人信息<br>
      二、我们如何使用COOKIES或同类技术<br>
      三、我们可能分享、转让和披露的个人信息<br>
      四、我们如何保留、储存和保护您的个人信息安全<br>
      五、如何管理您的个人信息<br>
      六、我们如何处理未成年人的个人信息<br>
      七、第三方服务<br>
      八、通知和修订<br>
      九、如何联系我们<br>
    </p>
    <p>
      <span class="special">【特别提示】</span> 请您在使用趴趴{{ieltsRes}}服务前，仔细阅读（未成年人请在监护人陪同下阅读）并了解本政策（特别是加粗或下划线标注的内容），以做出适当选择。一旦您使用或在我们更新本政策后继续使用我们的产品或服务，即意味着您同意本政策并同意我们按照本政策处理您的相关个人信息。
      本政策适用于趴趴{{ieltsRes}}服务，如与 <a :href="ieltsRes === '雅思' ? '/terms/public/terms/papaielts' : '/terms/public/terms'">《趴趴{{ieltsRes}}服务协议》</a> 同类条款之约定存在不一致的，以本政策为准。如我们提供的某一单项服务不适用本政策的，该服务中会以适当方式明示排除适用本政策。
    </p>
    <p class="mainTitle">一、我们如何收集和使用您的个人信息</p>
    <p>我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用趴趴{{ieltsRes}}服务。而这些个人信息有助于我们实现这一目标。您可通过<a :href="ieltsRes === '雅思' ? '/terms/public/protect/papaielts' : '/terms/public/protect'">《个人信息收集清单》</a>快速查看收集信息摘要，相关事宜详细阐述如下。</p>
    <p>1、您提供的个人信息。</p>
    <p>（1）您在注册趴趴{{ieltsRes}}服务的帐号或使用趴趴{{ieltsRes}}服务时，向我们提供的个人信息；</p>
    <p>（2）您通过趴趴{{ieltsRes}}服务向其他方提供的共享个人信息，以及您使用趴趴{{ieltsRes}}服务时所储存的个人信息。</p>
    <p>请注意，如您在趴趴{{ieltsRes}}服务中其他用户可见的公开区域内上传或发布的个人信息中、您对其他人上传或发布的信息作出的回应中公开您的个人信息，该个人信息可能会被他人收集并加以使用。当您发现他人不正当地收集或使用您的个人信息时，您可以通过本政策第九条<span>【如何联系我们】</span>约定的联系方式联络我们。</p>
    <p>2、其他方分享的您的个人信息。亦即其他方使用趴趴{{ieltsRes}}服务时所提供有关您的共享个人信息。</p>
    <p>3、我们获取的您的个人信息。您在使用趴趴{{ieltsRes}}服务时，我们收集、汇总、记录的个人信息。</p>
    <p class="mainTitle">二、我们会出于以下目的，收集和使用您以下类型的个人信息：</p>
    <p class="title">1、帮助您完成注册、登录及注销</p>
    <p>为便于我们为您提供完整的趴趴{{ieltsRes}}服务，您需要提供基本注册或登录个人信息，包括使用手机号码注册登录或提供第三方账号登录趴趴{{ieltsRes}}。如果您仅需使用浏览、搜索等基本服务，您不需要注册成为趴趴{{ieltsRes}}用户及提供上述个人信息。
        趴趴{{ieltsRes}}App提供的微信登录功能，会使用到微信SDK，登录成功后，微信SDK会访问到您的微信OpenID，这个ID不是微信号或者昵称，而是一个形如o9hHpv1megM0_8o21LQeA8FwzJuU 的无意义字符串，这个字符串只是用来识别微信用户，不包含任何其他个人信息。
    </p>
    <p>
      在趴趴{{ieltsRes}}服务注册、登录或后续使用过程中，您可以自行晚上您的个人信息，包括：您的昵称、头像、您的考试信息（包括考试科目、备考周期、学习方式），这些信息将有助于我们给您提供更优的服务体验，但如果您不提供这些个人信息，并不会影响您使用趴趴{{ieltsRes}}服务的基本功能。
    </p>
    <p>
      如果您不再使用趴趴{{ieltsRes}}服务，在符合服务协议约定条件及国家相关法律法规规定的情况下，您可以自行在“注销账户”页面（您可以在趴趴{{ieltsRes}}移动客户端【我的 → 设置 → 注销账户】，进入注销页面）提交趴趴{{ieltsRes}}账户注销申请。关于您注销账户的方式以及您应满足的条件，请详见“注销账户”页面的 《账号注销须知》 。您亦可以通过本政策最下方【如何联系我们】约定的联系方式联络我们，我们将为您提供注销趴趴{{ieltsRes}}账号的服务。
    </p>
    <p>
      在您的趴趴{{ieltsRes}}账户注销之后，我们将停止为您提供趴趴{{ieltsRes}}服务，并依据您的要求，删除或匿名化您的个人信息，但法律法规另有规定的除外。
    </p>
    <p class="title">2、维护基础功能的正常运行</p>
    <p>
      在您使用我们服务过程中，为识别账号异常状态、了解产品适配性，向您提供浏览、搜索等基本服务，维护基础功能的正常运行， 我们可能会自动收集、储存关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
    </p>
    <p>
      （1）日志信息：当您使用我们的服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。日志信息包括您的登录账号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、崩溃记录、停留时长、刷新记录、发布记录、分享。
    </p>
    <p>
      （2）设备信息：我们可能会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（包括IMEI、MAC、Serial、SIM卡IMSI识别码、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、进程及软件列表、唯一设备标识符、软硬件特征信息、获取Android ID、获取设备序列号）。
    </p>
    <p>
      （3）剪切板： 我们会在提问问题，评论，记录笔记等输入的情况 读取剪切板数据，使你可以更方便的从其他地方拷贝的信息。 当您在站外获取到由他人分享的趴趴{{ieltsRes}}服务（包含链接、口令、分享码）并想要在趴趴{{ieltsRes}}快捷打开、分享、联动时，我们需要通过读取剪切板包含的趴趴{{ieltsRes}}链接、口令、分享码来识别到您预期操作的服务。 需要特别注意的是：为了保护您的个人隐私，我们不会上传剪贴板中的个人隐私信息，仅用于识别我们的服务。
    </p>
    <p>
      同时为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们基本服务和基础功能，如您拒绝提供上述权限将可能导致您无法使用我们的产品与服务。
    </p>
    <p>
      请注意，单独的设备信息、日志信息是无法识别特定自然人身份的个人信息。如果我们将这类非个人信息与其他个人信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。
    </p>
    <p class="title">4、向您提供商品或服务</p>
    <p>
      （1）个人信息的分享与发布：您在使用趴趴{{ieltsRes}}服务时、上传和/或发布个人信息以及进行相关行为（例如发布提问、班群/学习群内发言及课程评价）时，我们将收集您上传、发布或形成的个人信息，并有权展示您的昵称、头像和发布内容。
    </p>
    <p>
      （2）商品或服务交付：当您在我们的商品或服务中订购具体商品或服务时，我们会通过系统为您生成购买该商品或服务的订单。趴趴{{ieltsRes}}部分课程服务会向您寄送随课物料，为便于向您交付商品或服务，您可能需提供 收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、支付状态信息。如果您拒绝提供此类个人信息，我们将无法完成相关交付服务。如您通过趴趴{{ieltsRes}}服务为其他人订购商品或服务，您需要提供该实际订购人的前述个人信息。 向我们提供该实际订购人的前述个人信息之前，您需确保您已经取得其授权同意。您在使用趴趴{{ieltsRes}}课程服务时，我们会记录您的学习个人信息：您当前的学习阶段、课程学习进度个人信息、测验结果、智能练习做题结果、学习时长。我们将应用这部分个人信息分析您的学习状态，为您或为我们的服务优化提供参考。
    </p>
    <p>
      （3）为完成订单支付、交付商品或服务、确认交易状态及为您提供售后与争议解决服务， 我们会通过您基于交易所选择的交易对象、支付机构、物流公司收集与交易进度相关的您的 账号、订单、交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。
    </p>
    <p>
      （4）订单管理：为展示您的账户的订单个人信息及保障您的售后权益，趴趴{{ieltsRes}}会收集您在使用趴趴{{ieltsRes}}过程中产生的 订单个人信息、交易和消费记录、虚拟财产个人信息（包括趴趴{{ieltsRes}}学习抵值券、积分） 用于向您展示及便于您对订单进行管理。
    </p>
    <p>
      （5）客服与售后服务： 当您联系我们的客服或使用其他用户响应功能时（包括提出售中售后申请、个人信息保护投诉或建议、其他客户投诉和需求）， 我们可能需要您提供必要的个人信息以匹配并核验您的用户身份，以便保障您的账号与系统安全。 我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
    </p>
    <p class="title">5、我们通过间接方式收集到的您的个人信息：</p>
    <p>
      我们可能从关联方、第三方合作伙伴获取您授权共享的相关个人信息。我们可能从第三方获取您授权共享的账户信息（头像、昵称、登录时间）并在您同意本政策后将您的第三方账户与您的趴趴{{ieltsRes}}服务账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的个人信息来源合法的前提下，收集并使用您的这些个人信息。
    </p>
    <p>
      我们将对您在我们平台的消费记录、浏览记录以及我们从您授权的第三方合作平台获取的您的个人信息（包括去标识化的个人信息）进行分析汇总，以向您提供我们的产品或服务、商品展示和发送营销信息。
    </p>
    <p class="title">6、向您提供商品或服务的信息展示</p>
    <p>（1）为您展示商品或服务</p>
    <p>基于您向我们提供的信息、我们可能收集的信息及我们通过间接方式收集到的您的信息（包括您的浏览及搜索记录、设备信息、订单信息、手机号码、您参与趴趴{{ieltsRes}}线上或线下活动填写问卷调查上的个人信息）， 我们可能会基于上述一项或几项信息的结合，用于提取您的 浏览、搜索偏好相关特征，以便向您提供更契合您需求的页面展示。</p>
    <p>我们也可能基于特征标签通过电子邮件、短信、电话或其他方式向您发送营销信息，提供我们或第三方的如下商品和服务：</p>
    <p>我们及我们关联方的商品和服务，包括：在线教育服务、语言翻译服务、语音识别和语音合成类开发平台服务、智能硬件服务、云应用服务、电子商务、即时通讯服务、网上媒体服务、互动娱乐服务、社交网络服务、支付服务、应用软件和服务、数据管理软件和服务、网上广告服务及其他社交媒体、娱乐、资讯及通讯软件和服务；及第三方商品和服务，包括：互联网服务、物流服务、银行及金融服务、娱乐、书册和其他刊物服务、网上广告服务、积分和奖励计划，以及我们认为可能与您相关的其他商品和服务。</p>
    <p>如您不希望继续接收我们的消息，您可要求我们停止，例如：根据短信退订指引要求我们停止发送短信；但我们依法律规定或服务协议约定发送消息的情形除外。</p>
    <p class="title">7、为您提供安全保障</p>
    <p>
      为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或趴趴{{ieltsRes}}服务相关协议规则的情况，我们可能会收集、使用或整合您的账户信息、交易信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的个人信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
    </p>
    <p class="title">8、改进我们的服务</p>
    <p>
      我们可能将通过趴趴{{ieltsRes}}服务所收集的个人信息，用于我们的其他服务：在您使用趴趴{{ieltsRes}}服务时所收集的您的个人信息，可能在我们的其他服务中用于向您提供特定内容或向您展示与您相关的的信息；我们可能让您参与有关趴趴{{ieltsRes}}服务的调查，帮助我们改善现有服务或设计新服务；同时，我们可能将您的个人信息用于软件更新。
    </p>
    <p class="title">9、其他用途</p>
    <p>您了解并同意，在收集您的个人信息后，我们可能通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用，而无需另行获得您的同意。</p>
    <p>请您注意，如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会另行事先请您同意（确认同意的方式：勾选、弹窗、站内信、邮件、短信方式）。一旦您同意，额外用途将视为本政策的一部分，该额外个人信息也将适用本政策。</p>
    <p class="title">10、征得授权同意的例外</p>
    <p>根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</p>
    <p>（1）与国家安全、国防安全有关的；</p>
    <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>（3）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p>（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
    <p>（5）所收集的个人信息是您自行向社会公众公开的；</p>
    <p>（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
    <p>（7）根据您的要求签订合同所必需的；</p>
    <p>（8）用于维护趴趴{{ieltsRes}}服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
    <p>（9）为合法的新闻报道所必需的；</p>
    <p>（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
    <p>（11）法律法规规定的其他情形。</p>
    <p class="title">11、您理解并同意，趴趴{{ieltsRes}}服务可能需要您在您的设备中开启特定的访问权限，以实现这些权限所涉及个人信息的收集和使用：</p>
    <p>（1）如我们需要存储的权限，是为了在某些下载功能里，把下载的内容存储来下。</p>
    <p>（2）如我们访问您的摄像头，是为了使您可以使用摄像头进行扫码、拍摄，用于登录、支付、拍摄并上传图片以进行晒图评价或相应的分享、上传课程作业/资料、观看视频或用于课程直播中的互动；</p>
    <p>（3）如我们访问您的相册，是为了使您可以实现您设备中的照片、图片或视频的取用与上传，便于您进行更换头像、发表评论/分享；</p>
    <p>（4）您使用趴趴{{ieltsRes}}直录播服务，会生成视频缓存文件，我们会申请访问该视频缓存文件，是为了使您更为流畅的体验我们的课程服务或为您提供课程离线观看功能。</p>
    <p>当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的个人信息收集及使用。</p>
    <p class="title">12、有关个人敏感信息的提示</p>
    <p>以上由您提供或我们收集您的个人信息中，可能包含您的个人敏感信息，包括银行账号、交易和消费记录、虚拟财产信息、系统账号、邮箱地址及其有关的密码、电话号码、网页浏览记录。请您谨慎并留意个人敏感信息，您同意您的个人敏感信息我们可以按本政策所述的目的和方式来处理。</p>
    <p>（1）趴趴{{ieltsRes}} App 所调用的系统权限：</p>
    <p>为了向您提供趴趴{{ieltsRes}}产品和/或服务，我们可能需要使用您设备中的系统权限来收集相关个人信息。 本部分将对趴趴{{ieltsRes}}涉及的所有系统权限进行归纳整理，以方便向您更加系统、完整、透明地呈现。您可以选择是否授权我们使用您设备的相关权限，即使授权后也允许您自行关闭或者再开启。 关于权限的具体信息请您详见<a :href="ieltsRes === '雅思' ? '/terms/public/permission/papaielts' : '/terms/public/permission' ">《趴趴{{ieltsRes}} App 权限列表》</a>。</p>
    <p class="mainTitle">三、我们可能分享、转让或披露的个人信息</p>
    <p>（1）分享</p>
    <p>除以下情形外，未经您同意，我们不会与我们及我们的关联方之外的任何第三方分享您的个人信息：</p>
    <p>1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的个人信息，以实现您需要的核心功能或提供您需要的服务，例如：向物流服务商提供对应的订单信息；</p>
    <p>为了您在合作方获得我们提供的服务，我们会在取得您的明确同意后，将您的账户信息、手机号码和操作日志等最小必要字段提供至合作方，以便于您在合作方完成账户注册、完善您在合作方的账户信息，并使得您在合作方平台能获取我们提供的服务。具体合作方名称及联系方式以届时授权/服务页面披露为准。</p>
    <p>2、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的个人信息，以帮助我们为您提供更有针对性、更完善的服务，例如：代表我们发出电子邮件的通讯服务提供商；</p>
    <p>3、实现本政策第一条“我们如何收集和使用您的个人信息”部分所述目的；</p>
    <p>4、履行我们在本政策或我们与您达成的其他协议中的义务和行使我们的权利；</p>
    <p>5、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他趴趴{{ieltsRes}}用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换个人信息。不过,这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。</p>
    <p>6、应您合法需求，协助处理您与他人的纠纷或争议；</p>
    <p>7、应您的监护人合法要求而提供您的个人信息；</p>
    <p>8、根据与您签署的服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
    <p>9、基于学术研究而提供；</p>
    <p>10、基于符合法律法规的社会公共利益而提供。</p>
    <p>我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
    <p>（2）转让</p>
    <p>1、 随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移。 我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>
    <p>2、在获得您的明确同意后，我们会向其他方转让您的个人信息。</p>
    <p>（3）披露</p>
    <p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：</p>
    <p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
    <p>2、 根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露个人信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的个人信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</p>
    <p>（4）分享、转让、披露个人信息时事先征得授权同意的例外</p>
    <p>以下情形中，分享、转让、披露您的个人信息无需事先征得您的授权同意：</p>
    <p>1、与国家安全、国防安全有关的；</p>
    <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
    <p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p>5、您自行向社会公众公开的个人信息；</p>
    <p>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
    <p>根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
    <p class="mainTitle">四、我们如何保留、储存和保护您的个人信息安全</p>
    <p>
      （一）我们仅在本政策所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息。如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。 我们将对从中华人民共和国境内获得的用户个人信息存放于中华人民共和国境内，并在本协议所述目的所需的最短期限内保留您的个人信息。超出保存期限后，我们会对您的个人信息进行删除或者匿名化处理，但国家法律法规、规章、规范性文件或政府的政策、命令等另有要求或为履行我们的合规义务而需要保留您的个人信息的除外。例如：《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年；《中华人民共和国网络安全法》要求采取监测、记录网络运行状态、网络安全事件的技术措施，并按照规定留存相关的网络日志不少于六个月。
    </p>
    <p>
      （二）我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内。以下情形除外：
    </p>
    <p>1、法律法规有明确规定；</p>
    <p>2、获得您的授权同意；</p>
    <p>3、您使用的产品、服务涉及跨境，我们需要向境外提供您的个人信息的。</p>
    <p>针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。</p>
    <p>（三）我们非常重视个人信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息：</p>
    <p>1、数据安全技术措施</p>
    <p>我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的个人信息，采取加密技术对您的个人信息进行加密保存，并通过隔离技术进行隔离。 在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用多种数据脱敏技术增强个人信息在使用中的安全性。采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。</p>
    <p>2、我们为保护个人信息采取的其他安全措施</p>
    <p>（1）我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。</p>
    <p>（2）我们通过个人信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</p>
    <p>（3）我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识和安全意识。</p>
    <p>（4） 我们仅允许有必要知晓这些个人信息的我们及我们关联方的员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。</p>
    <p>3、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</p>
    <p>4、我们将尽力确保或担保您发送给我们的任何个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致个人信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
    <p>（四）安全事件处置</p>
    <p>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、短信、电话等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
    <p>请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证个人信息百分之百的安全。您需要了解，您接入趴趴{{ieltsRes}}服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</p>
    <p class="mainTitle">五、如何管理您的个人信息</p>
    <p>我们鼓励您更新和修改您的个人信息以使其更准确有效，也请您理解， 您更正、删除、撤回授权或停止使用趴趴{{ieltsRes}}服务的决定，并不影响我们此前基于您的授权而开展的个人信息处理。除法律法规另有规定，当您更正、删除您的个人信息或申请注销账号时，我们可能不会立即从备份系统中更正或删除相应的个人信息，但会在备份更新时更正或删除这些个人信息。</p>
    <p>您可以通过以下方式来管理您的个人信息：</p>
    <p>（一）访问、更正您的个人信息</p>
    <p>1、个人头像信息：如果您希望访问您的账户中的个人头像信息，您可以登录移动客户端账号通过【我的】查看。</p>
    <p>2、账户个人信息：如果您希望访问或编辑您的账户中的基本个人信息（包括昵称，手机号，邮箱），您可以登录移动客户端账号通过【我的 → 点击昵称、】执行此类操作。</p>
    <p>3、订单个人信息：您可以通过【我的 → 订单】查看您的所有订单状态。</p>
    <p>4、地址个人信息：如果您希望访问或编辑您的账户中的地址信息，您可以登录移动客户端账号通过【我的 → 收货】执行此类操作。</p>
    <p>5、如您需要获取您的个人信息副本，您可通过联系我们的客服【请填入联系方式】后申请下载已收集的您的个人信息。</p>
    <p>如果您无法通过上述路径访问、更正该个人信息，您可以通过本政策第九条【如何联系我们】约定的联系方式与我们取得联系。对于您在使用趴趴{{ieltsRes}}服务过程中产生的其他个人信息需要访问、更正，我们会根据本政策所列明的方式、期限及范围来响应您的请求。</p>
    <p>（二）删除您的个人信息</p>
    <p>
      在以下情形中，我们将主动删除您的个人信息。如我们未删除的，您可通过【请填入联系方式】联系我们提出删除个人信息的请求： （1）处理目的已实现、无法实现或者为实现处理目的不再必要； （2） 我们停止提供产品或者服务，或者保存期限已届满； （3）您撤回同意； （4）我们违反法律、行政法规或者违反约定处理个人信息； （5）法律、行政法规规定的其他情形。
      您删除信息后，我们将据此调整向您提供的产品和/或服务。但请您理解，这不会影响此前基于您的授权而开展的个人信息处理。
    </p>
    <p>（四）改变您授权同意的范围</p>
    <p>
      您总是可以选择是否披露个人信息。有些个人信息是使用趴趴{{ieltsRes}}服务所必需的，但大多数其他个人信息的提供是由您决定的。您可以通过删除个人信息、关闭设备功能等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。
      您可以通过本隐私协议第九条规定的联系方式与我们联系，以行使您的本项权利。
    </p>
    <p class="mainTitle">六、我们如何处理未成年人的个人信息</p>
    <p>我们的产品、网站和服务主要面向成年人。若您是 18 周岁以下的未成年人，请您不要或终止使用我们的产品和/或服务。</p>
    <p>若您是未成年人的监护人，当您对您所监护的未成年人是否使用我们的服务或其向我们是否提供信息有任何疑问时，请您及时按照第九条所述方式与我们联系。如果我们发现自己在不知情的情况下收集了未成年人的个人信息，会设法尽快删除相关数据。</p>
    <p class="mainTitle">七、第三方服务</p>
    <p>趴趴{{ieltsRes}}服务可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：</p>
    <p>(一)您可利用<span class="special">“分享”</span>键将某些趴趴{{ieltsRes}}服务内容分享到第三方服务，或您将第三方服务内容分享到趴趴{{ieltsRes}}服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能；</p>
    <p>(二)我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站；</p>
    <p>(三)其他接入第三方服务的情形。 为实现本政策中声明的目的，我们可能会接入第三方服务商提供的SDK或其他类似的应用程序，并将我们依照本政策收集的您的某些信息共享给该第三方服务商，以便提供更好的客户服务和用户体验。目前，我们接入的第三方服务商主要包括以下几种类型：</p>
    <p>1、 用于进行广告服务，包括广告展示、广告数据监测/统计；</p>
    <p>2、 用于在您同意的情况下搜集设备信息和日志信息；</p>
    <p>3、 用于支付相关服务及汇总/统计支付信息：包括订单支付、交易行为核验、收入结算、支付信息汇总统计；</p>
    <p>4、 用于第三方授权服务，包括本机一键登录、第三方账号登录、将相关内容分享至第三方产品；</p>
    <p>5、 用于支持产品功能模块，包括语音识别、翻译服务、在线直播、视频播放、连接智能硬件、客服；</p>
    <p>6、 用于优化产品性能，包括统计数据崩溃信息。</p>
    <p>(四)趴趴{{ieltsRes}}APP接入第三方SDK目录</p>
    <p>1、为保障趴趴{{ieltsRes}}APP相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包（SDK）实现相关目的。</p>
    <p>2、我们会对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。</p>
    <p>3、关于我们的合作方（委托处理）收集个人信息类型及收集目的，详情请参阅<a class="third" :href="ieltsRes === '雅思' ? '/terms/public/third/papaielts' : '/terms/public/third'">《与第三方共享个人信息清单》</a>。</p>
    <p>
      如上所述服务由相关的第三方负责运营。您使用该第三方服务（包括您向该第三方提供的任何个人信息），须受第三方自己的服务条款及个人信息保护声明（而非本政策）约束，您需要仔细阅读其条款。我们仅会出于正当、必要、特定的目的共享您的信息。我们会要求他们履行相关保密义务并采取相应的安全措施。
    </p>
    <p>（五）委托处理</p>
    <p>为了提升信息处理和服务效率和准确性，我们会委托专业机构代表我们来处理您的个人信息，如基础设施技术服务、短信分发服务、实人认证服务、开票服务、物流服务、检验检测服务、数据存储和分析等服务提供商。上述委托处理行为受本政策中所声明目的约束，并且我们会通过书面协议、审计等方式要求受托机构要求遵守严格的保密义务及采取有效的保密措施，禁止其将受托处理的信息用于受托目的之外的用途。</p>
    <p class="mainTitle">八、通知和修订</p>
    <p>我们可能适时修改本政策的条款，该修改构成本政策的一部分。对于重大变更，我们会提供更显著的通知，您如果不同意该变更，可以选择停止使用趴趴{{ieltsRes}}服务；如您仍然继续使用趴趴{{ieltsRes}}服务的，即表示同意受经修订的本政策的约束。您可在本平台中通过【个人中心 → 设置 →隐私政策】查看最新内容。</p>
    <p>我们鼓励您在每次使用趴趴{{ieltsRes}}服务时都查阅我们的隐私政策。</p>
    <p>我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。</p>
    <p>最后，您必须对您的账号和密码信息负有保密义务。任何情况下，请小心妥善保管。</p>
    <p class="mainTitle">九、如何联系我们</p>
    <p>上海趴趴{{ieltsRes}}科技有限公司的联系地址为：上海市杨浦区黄兴路2218号合生国际中心19楼。我们将按照本政策保护您的个人信息。</p>
    <p>
      <span
        >我们设立了个人信息保护专职部门，如您有关于个人信息安全的投诉和举报，或您对本政策、您的个人信息的相关事宜有任何问题、意见或建议，以及有关本政策或趴趴{{ieltsRes}}的隐私措施的问题，请与我们联系
        </span
      ><br />【<span>联系电话</span>】：<a href="tel:021-61070256"
        >021-61070256</a
      ><br />【<span>联系邮箱</span>】：<a
        href="mailto:support@papaenglish.cn"
        >support@papaenglish.cn</a
      ><br /><span>一般情况下，我们将在收到您的问题、意见或建议之日起</span>15天<span
        >内予以回复。</span
      >
    </p>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useRoute } from 'vue-router'

  const route = useRoute()
  const ieltsRes = ref('')
  if(route.params.params === 'papaielts') {
    ieltsRes.value = '雅思'
  }else {
    ieltsRes.value = '英语'
  }
</script>

<style scoped>
@import './explain.css';
</style>
