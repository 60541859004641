import { ref } from 'vue'

export function useIsAgreeTerms() {
  const isAgree = ref<boolean>(false)

  function handleIsAgree() {
    isAgree.value = !isAgree.value
  }

  return {
    isAgree,
    handleIsAgree
  }
}
