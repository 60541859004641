import { useRoute, useRouter } from 'vue-router'
import { captchaLogin, forgot, loginApi, wechat } from '../api/login'
import { useCookies } from 'vue3-cookies'
import { ELoginType } from '../enums'
import { ILoginParam, ILoginResponse } from '../types'
import { checkDevice } from '../util/utils'
import { useLoginType } from './useLoginType'

const { changeLoginType } = useLoginType()
const { cookies } = useCookies()
const { VITE_COOKIE_DOMAIN } = import.meta.env


export function useLogin() {

  async function loginCaptcha(params: { username: string, captcha: number, iso_code: string }, url: string) {
    try {
      await login(ELoginType.captcha, params, url)
    } catch (e) {
      console.log(e)
    }
  }

  async function loginPassword(params: { username: string, password: string, iso_code: string }, url: string) {
    try {
      await login(ELoginType.password, params, url)
    } catch (e) {
      console.log(e)
    }
  }

  async function loginForgot(params: { username: string, captcha: number, password: string, iso_code: string }, url) {
    try {
      await login(ELoginType.forgot, params, url)
    } catch (e) {
      console.log(e)
    }
  }

  async function loginWechat(params: { code: string | number }, url: string) {
    const route = useRoute()
    const router = useRouter()
    try {
      const res = await wechat(params)
      if (res.data.openid) {
        changeLoginType(ELoginType.bind)
        await router.push({
          path: '/',
          query: {
            ...route.query,
            openid: res.data.openid
          }
        })
        // window.location.href = '/login'+'&openid='+res.openid
      } else {
        await afterLogin(res.data, url)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function login(loginType: ELoginType, params: ILoginParam, url: string) {
    try {
      let res
      switch (loginType) {
        case ELoginType.captcha:
          res = await captchaLogin(params)
          afterLogin(res.data, url)
          break
        case ELoginType.password:
          res = await loginApi(params)
          afterLogin(res.data, url)
          break
        case ELoginType.forgot:
          res = await forgot(params)
          afterLogin(res.data, url)
          break
        default:
          break
      }
    } catch (e) {
      console.log(e)
    }
  }

  function afterLogin(data: ILoginResponse, url: string) {
    // @ts-ignore
    console.log(url)
    cookies.set('authorization', data, '20d', '', VITE_COOKIE_DOMAIN)
    const device = checkDevice()
    if (device === 'pc') {
      window.postMessage('authorization', url)
    }
    setTimeout(() => {
      window.location.href = url
    }, 1000)
  }


  async function sendCode(loginType: ELoginType, username: string) {
    // await checkUsername(username)

  }


  return {
    loginCaptcha,
    loginPassword,
    loginForgot,
    sendCode,
    loginWechat
  }
}

