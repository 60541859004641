<template>
  <div class="check">
    <img src="../assets/img/load.gif" alt="">
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import { isWeChat } from '../util/utils'
  import { useLogin } from '../hooks/useLogin'


  const { loginWechat } = useLogin()

  const isInWeChat = isWeChat()
  const route = useRoute()
  onMounted(() => {
    if (isInWeChat) {
      if (route.query.code) {
        const params = {
          code: route.query.code
        }
        const redirectUrl = route.query.redirect_url
        loginWechat(params, redirectUrl)
      }
    }
  })


</script>

<style lang="less" scoped>
  .check {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    line-height: 100vh;
    background: #fff;
    i {
      font-size: 50px;
      position: absolute;
      left: 50%;
      margin-left: -25px;
    }

  }

  .spin {
    animation: spin 1s steps(12) infinite;

  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

</style>
