<template>
  <div class="pc">
    <div class="bg-right">
      <img src="../assets/img/login-bg-right.png" alt="">
    </div>
    <div class="bg-middle">
      <img src="../assets/img/login-bg-mid.png" alt="">
    </div>
    <div class="pc-container" @click.stop.prevent="handleShowIsoCodePanel(false)">
      <div class="logo-box">
        <div class="logo">
          <img :src="appLogo" alt="">
        </div>
        <div class="logo-slide"></div>
        <div class="logo-slogan">未来精英的留学备考管家</div>
      </div>
      <div class="app-box">
        <div class="app-desc">登录的账号适用于趴趴教育旗下所有网站 <br>
          及以下App移动端产品
        </div>
        <div class="app-list">
          <div class="app-item" v-for="app in apps" @click="handleGoApp(app.url)">
            <div class="app-logo">
              <img :src="app.logo" alt="">
            </div>
            <div class="app-name">
              {{ app.name }}
            </div>
          </div>
        </div>

      </div>
      <div class="login-box">
        <div class="login-type" v-if="loginType !==ELoginType.forgot">
          <div class="type-title" :class="loginType === ELoginType.captcha?'type-title-active':''"
               @click="changeLoginType(ELoginType.captcha)">验证码登录
          </div>
          <div class="type-title" :class="loginType === ELoginType.password? 'type-title-active':''"
               @click="changeLoginType(ELoginType.password)">密码登录
          </div>
        </div>
        <div class="login-type" v-else>
          <div class="type-title type-forgot-active"
               @click="changeLoginType(ELoginType.password)">
            <i class="iconfont papa-chevron-left"></i>
            忘记密码
          </div>
        </div>
        <div class="login-form">
          <div class="form-item">
            <div class="iso-code">
              <span class="code" @click.stop.prevent="handleShowIsoCodePanel(true)">+{{ iddCode }}</span>
              <i class="iconfont papa-open" @click.stop.prevent="handleShowIsoCodePanel(true)"></i>
              <div class="panel-box" v-if="isShowIsoCodePanel">
                <div class="iso-code-panel">
                  <div class="iso-code-item" v-for="country in countryList" @click="handleSetCountryCode(country)">
                    <div class="country">{{ country.name }}</div>
                    <div class="country-code">+{{ country.idd_code }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide"></div>
            <div class="username" :class="{'input-empty-tip': !firstUsernameInput}">
              <input type="text"
                     placeholder="请输入手机号"
                     v-model.trim="username"
                     @input="onUsernameChange"
                     @blur="checkUsername"
              >
              <span class="error-message">{{ usernameMessage }}</span>
            </div>
            <i v-show="username" class="iconfont close-icon papa-cancer" @click="handleClear('username')"></i>
          </div>
          <div class="form-item" v-if="loginType !== ELoginType.password">
            <div class="verify-code" :class="{'input-empty-tip': !firstCaptchaInput}">
              <input type="text"
                     placeholder="请输入短信验证码"
                     v-model.trim="captcha"
                     @blur="onCaptchaChange"
              >
            </div>
            <i v-show="captcha" class="iconfont close-icon papa-cancer" @click="handleClear('captcha')"></i>
            <div class="send-code" v-if="canSendCode || !sendSuccess" @click="handleSendCode">获取验证码</div>
            <div class="send-code" v-else>{{ timer }}s</div>
            <span class="error-message" v-if="captcha&&captcha.length>0 && captchaMessage!=='验证码不能为空'">{{ captchaMessage }}</span>
          </div>
          <div class="form-item" v-if="loginType === ELoginType.password">
            <div class="verify-code" :class="{'input-empty-tip': !firstPasswordInput}">
              <input :type="passwordInputType"
                     placeholder="请输入密码"
                     v-model.trim="password"
                     @blur="onPasswordChange"
              >
            </div>

            <i v-show="password" class="iconfont close-icon papa-cancer" @click="handleClear('password')"></i>
            <i class="iconfont papa-hidepassword" v-if="passwordInputType !== 'text'"
               @click="handleChangePasswordInputType"></i>
            <i class="iconfont papa-showpassword" v-else @click="handleChangePasswordInputType"></i>
            <div class="send-code" @click="changeLoginType(ELoginType.forgot)">忘记密码</div>
<!--            <span class="error-message" v-if="password?.length>0">{{ passwordMessage }}</span>-->
          </div>
          <div class="form-item" v-if="loginType === ELoginType.forgot">
            <div class="verify-code" :class="{'input-empty-tip': !firstPasswordInput}">
              <input :type="passwordInputType"
                     placeholder="请输入密码"
                     v-model.trim="password"
                     @input="onPasswordChange"
              >
            </div>
            <i v-show="password" class="iconfont close-icon papa-cancer" @click="handleClear('password')"></i>
            <i class="iconfont papa-hidepassword" v-if="passwordInputType !== 'text'"
               @click="handleChangePasswordInputType"></i>
            <i class="iconfont papa-showpassword" v-else @click="handleChangePasswordInputType"></i>
<!--            <span class="error-message" v-if="password?.length>0">{{ passwordMessage }}</span>-->
          </div>
          <div class="button-box">
            <div class="login-button hover-btn" v-if="loginType === ELoginType.captcha"
                 @click="handleLogin(ELoginType.captcha)">
              登录/注册
            </div>
            <div class="login-button hover-btn" v-else-if="loginType === ELoginType.password"
                 @click="handleLogin(ELoginType.password)">登录
            </div>
            <div class="login-button hover-btn" v-else-if="loginType === ELoginType.forgot"
                 @click="handleLogin(ELoginType.forgot)">确认修改
            </div>
            <div class="login-button hover-btn" v-else>登录/注册</div>
          </div>
          <div class="terms" v-if="loginType !== ELoginType.forgot">
            <i class="iconfont papa-checkbox-checked" @click="handleIsAgree" v-if="isAgree"></i>
            <i class="iconfont papa-checkbox-unchecked" @click="handleIsAgree" v-else></i>
            <div class="terms-con"><span @click="handleIsAgree">未注册账号的手机号，登录时将自动注册，且代表已同意</span>
              <a @click="handleGoApp('/agreement')" href="">《用户协议》</a>和
              <a @click="handleGoApp('/privacy')">《隐私政策》</a></div>
          </div>
          <div class="other-login">
            <!--            <div class="label">其他登录方式:</div>-->
            <!--            <div class="login-item">微信</div>-->
          </div>
        </div>
        <div class="agree-modal-box" v-show="showAgreeModal">
          <div class="mask" />
          <div class="content">
            <h4>提示</h4>
            <i class="agree-close iconfont close-icon papa-cancer" @click="showAgreeModal = false"></i>
            <p>请先同意<span>《用户协议》</span>和<span>《隐私政策》</span></p>
            <div class="btn-box">
              <div class="cancel hover-btn" @click="showAgreeModal = false">取消</div>
              <div class="confirm hover-btn" @click="handleAgreeAndContinue">同意并登录</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-box" v-if="windowHeight>500"> <a class="copyright" href="https://beian.miit.gov.cn">
      Copyright © 2015-2023 上海彼伴网络科技有限公司版权所有 沪ICP备2023023608号-2
    </a></div>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import { ELoginType } from '../enums'
  import papaLogo from '../../src/assets/img/papa.png'
  import ieltsLogo from '../../src/assets/img/ielts.png'
  import gmatLogo from '../../src/assets/img/gmat.png'
  import appLogo from '../../src/assets/img/logo.png'
  import { useLogin } from '../hooks/useLogin'
  import { useLoginType } from '../hooks/useLoginType'
  import { useIsoCode } from '../hooks/useIsoCode'
  import { useUserFormItem } from '../hooks/useUserFormItem'
  import { useCaptcha } from '../hooks/useCaptcha'
  import { useIsAgreeTerms } from '../hooks/useIsAgreeTerms'
  import { ElMessage } from 'element-plus'
  import { usePassword } from '../hooks/usePassword'
  import { ICountryItem } from '../types'
  import { throttle } from 'lodash'


  const { loginType, changeLoginType } = useLoginType()
  const {
    iddCode,
    currentIsoCode,
    countryList,
    isShowIsoCodePanel,
    handleShowIsoCodePanel,
    handleChooseCountry
  } = useIsoCode()
  const {
    firstUsernameInput,
    username,
    usernameMessage,
    onUsernameChange,
    checkUsername,
    getIsoCode
  } = useUserFormItem()
  const {
    firstCaptchaInput,
    captcha,
    captchaMessage,
    canSendCode,
    timer,
    countDown,
    onCaptchaChange,
    sendCode,
    sendSuccess
  } = useCaptcha(username, currentIsoCode, loginType)
  const {
    firstPasswordInput,
    password,
    passwordMessage,
    passwordInputType,
    onPasswordChange,
    checkPassword,
    handleChangePasswordInputType
  } = usePassword()
  const { isAgree, handleIsAgree } = useIsAgreeTerms()
  const { loginCaptcha, loginPassword, loginForgot } = useLogin()
  const apps = [
    {
      logo: papaLogo,
      name: '趴趴英语',
      url: 'https://papaen.com'
    },
    {
      logo: ieltsLogo,
      name: '趴趴雅思',
      url: 'https://ielts.papaen.com'
    },
    {
      logo: gmatLogo,
      name: '趴趴GMAT',
      url: 'https://gmat.papaen.com'
    },
    {
      logo: papaLogo,
      name: '趴趴托福',
      url: 'https://toefl.papaen.com'
    }
  ]
  const showAgreeModal = ref(false)

  const windowHeight = ref(0)
  onMounted(()=>{
    windowHeight.value = window.innerHeight
  })


  function handleClear(type: string) {
    switch (type) {
      case 'username':
        username.value = ''
        usernameMessage.value = ''
        break
      case 'password':
        password.value = ''
        passwordMessage.value = ''
        break
      case 'captcha':
        captcha.value = undefined
        captchaMessage.value = ''
        break
      default:
        break
    }
  }

  const route = useRoute()

  const isSubmitting = ref(false)
  const handleLogin = throttle(async (type) => {

    if(isSubmitting.value) return
    isSubmitting.value = true

    try{
      await _handleLogin(type)
    }finally {
      isSubmitting.value = false
    }
  }, 2000, { trailing: false })

  async function _handleLogin(type: ELoginType) {
    const url = route.query.redirect_url as string
    try {
      await checkUsername()

      switch (type) {
        case ELoginType.captcha:
          await onCaptchaChange()
          if (!isAgree.value && type !== ELoginType.bind && type !== ELoginType.forgot) {
            showAgreeModal.value = true
            return
          }
          break
        case
        ELoginType.password:
          // await checkPassword(password.value)
          await onPasswordChange()
          if (!isAgree.value && type !== ELoginType.bind && type !== ELoginType.forgot) {
            showAgreeModal.value = true
            return
          }
          break
        case ELoginType.forgot:
          await onCaptchaChange()
          await onPasswordChange()
      }
      await handleAgreeAndContinue()
    } catch (e) {
      console.log(e)
    }

  }

  //同意并登录
  async function handleAgreeAndContinue() {
    isAgree.value = true
    showAgreeModal.value = false
    const url = route.query.redirect_url as string
    const methodMap: any = {
      [ELoginType.captcha]: loginCaptcha,
      [ELoginType.password]: loginPassword,
      [ELoginType.forgot]: loginForgot
    }
    const methodParamsMap: any = {
      [ELoginType.captcha]: {
        username: username.value,
        captcha: captcha.value as number,
        iso_code: currentIsoCode.value
      },
      [ELoginType.password]: {
        username: username.value,
        password: password.value,
        iso_code: currentIsoCode.value
      },
      [ELoginType.forgot]: {
        username: username.value,
        password: password.value,
        captcha: captcha.value as number,
        iso_code: currentIsoCode.value
      }
    }
    try {
      await methodMap[loginType.value](methodParamsMap[loginType.value], url)
    } catch (e) {
      console.log(e)
      ElMessage.error(e.data.message)
    }

  }

  const handleSendCode = throttle(()=>{
    _handleSendCode()
  },2000,{
    trailing:false
  })
  async function _handleSendCode() {
    sendSuccess.value = false
    try {
      await checkUsername()
      await sendCode()
    } catch (e) {
      console.log(e)
    }
  }

  async function handleSetCountryCode(country: ICountryItem) {
    await handleChooseCountry(country)
    getIsoCode(country.iso_code)
    checkUsername()
  }

  function handleGoApp(url) {
    window.open(url)
  }


</script>

<style scoped lang="less">
  .pc {
    width: 100vw;
    height: 100vh;
    position: relative;
    font-size: 12px;
    background: url("../assets/img/login-bg.png") no-repeat #fff;
    background-size: cover;
    input, textarea {
      font-size: 14px; /* 设置输入框字体大小 */
      -webkit-text-size-adjust: 100%; /* 禁用自动缩放 */
    }

    .bg-middle {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      bottom: 0;
      overflow: hidden;

      img {
        width: 973px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -656px;
        margin-top: -300px;
      }
    }

    .bg-right {
      width: 236px;
      height: 247px;
      z-index: 1;
      position: absolute;
      bottom: 0;
      right: 0;
      overflow: hidden;

      & > img {
        width: 100%;
        position: absolute;
        right: -12px;
        bottom: -6px;
      }
    }

    .pc-container {
      box-sizing: border-box;
      padding: 90px 120px 0 100px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      z-index: 2;
      position: relative;

      .logo-box {
        box-sizing: border-box;
        padding: 0 0 60px 0;
        display: flex;
        align-items: center;

        .logo {
          width: 120px;
          font-size: 0;

          img {
            width: 100%;
          }
        }

        .logo-slide {
          width: 1px;
          height: 20px;
          background: #D9D9D9;
          margin: 0 32px;
        }

        .logo-slogan {
          font-size: 28px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.9);
        }
      }

      .app-box {
        .app-desc {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.9);
          margin-bottom: 20px;
          font-weight: 400;
        }

        .app-list {
          display: flex;

          .app-item {
            width: 70px;
            height: 90px;
            margin-right: 24px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.7);
            cursor: pointer;

            img {
              width: 64px;
            }

            .app-name {
              font-weight: bold;
              text-align: center;
              white-space: nowrap;
            }
          }
        }
      }

      .login-box {
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        right: 8vw;
        margin-top: -201px;
        width: 420px;
        height: 402px;
        padding: 30px;
        background: #ffffff;
        box-shadow: 0 2px 20px 0 rgba(85, 85, 85, 0.1);
        border-radius: 16px;
        z-index: 2;

        .login-type {
          display: flex;
          height: 27px;

          margin-bottom: 36px;

          .type-title {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.3);
            font-weight: 500;
            margin-right: 28px;
            cursor: pointer;
          }

          .type-title-active {
            color: rgba(0, 0, 0, 0.9);
            font-weight: bold;
            position: relative;
          }

          .type-forgot-active {
            color: rgba(0, 0, 0, 0.9);
            font-weight: bold;
            position: relative;
            display: flex;
            align-items: center;

            .papa-chevron-left {
              font-size: 22px;
              font-weight: bold;
            }
          }

          .type-title-active:after {
            content: '';
            position: absolute;
            bottom: -16px;
            left: 0;
            width: 100%;
            height: 4px;
            background: #000;
          }
        }

        .agree-modal-box {
          position: relative;

          .mask {
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 3;
          }

          .content {
            position: absolute;
            top: -240px;
            width: 280px;
            padding: 20px 40px;
            text-align: center;
            border-radius: 5px;
            opacity: 1;
            background: #fff;
            z-index: 4;

            > h4 {
              font-size: 20px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.9);
              line-height: 28px;
            }

            > p {
              width: 320px;
              height: 22px;
              margin: 15px 0;
              font-size: 14px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.6);
              line-height: 22px;

              span {
                color: rgba(41, 208, 135, 1);
                cursor: pointer;
              }
            }

            .agree-close {
              position: absolute;
              right: 10px;
              top: 10px;
              font-size: 18px;
              font-weight: bold;
              cursor: pointer;
            }

            .btn-box {
              display: flex;
              justify-content: center;
              gap: 10px;

              .cancel {
                height: 32px;
                line-height: 32px;
                text-align: center;
                padding: 0 20px;
                border-radius: 32px;
                color: rgba(0, 0, 0, 0.1);
                border: 1px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;
              }

              .confirm {
                height: 32px;
                line-height: 32px;
                text-align: center;
                padding: 0 20px;
                border-radius: 32px;
                color: #fff;
                cursor: pointer;
                background: rgba(41, 208, 135, 1);
                border: 1px solid rgba(41, 208, 135, 1);
              }
            }
          }
        }
      }

      .form-item {
        box-sizing: border-box;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        width: 360px;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #EEEEEE;
        position: relative;

        .slide {
          margin: 0 20px;
          width: 1px;
          height: 20px;
          background: #d9d9d9;
        }

        .iso-code {
          width: 48px;
          font-size: 14px;
          cursor: pointer;
          position: relative;

          .code {
            box-sizing: border-box;
            display: inline-block;
            width: 34px;
            color: #000;
            padding-right: 6px;
          }

          .panel-box {
            position: absolute;
            top: 60px;
            width: 200px;
            height: 200px;
            overflow: auto;
            z-index: 2;
            box-shadow: rgba(0, 0, 0, 0.12) 0 0 12px 0;
            border: 1px solid #e4e7ed;
            border-radius: 8px;

            .iso-code-panel {
              background: #fff;
              display: flex;
              flex-direction: column;
              overflow: hidden;

              .iso-code-item {
                width: 100%;
                height: 45px;
                box-sizing: border-box;
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                color: black;

                .country-code {
                  text-align: right;
                }
              }

              .iso-code-item:hover {
                background: #f5f7fa;
              }
            }
          }
        }

        .username, .verify-code {
          flex: 1;
        }

        .iconfont {
          font-size: 12px;
          color: #000;
          cursor: pointer;
          transition: all 0.15s ease-in-out;

          &:hover {
            transform: scale(1.2);
          }

          &:active {
            transform: scale(0.8);
          }
        }

        .close-icon {
          cursor: pointer;
          user-select: none;
          font-weight: bold;
          font-size: 14px;
        }

        .send-code {
          width: 6em;
          text-align: right;
          font-size: 14px;
          cursor: pointer;
          color: #29d087;
          user-select: none;
        }

        input {
          border: none;
          outline: none;
          width: 100%;
          height: 49px;
          color: #333;
        }

        .input-empty-tip {
          input::-webkit-input-placeholder {
            color: red;
          }
        }

        .papa-showpassword, .papa-hidepassword {
          font-size: 22px;
          margin-left: 10px;
        }

        .username .error-message {
          right: 20px;
        }

        .error-message {
          position: absolute;
          bottom: 0;
          right: 85px;
          color: red;
          font-size: 14px;
        }

      }

      .button-box {
        width: 100%;
        user-select: none;
        cursor: pointer;

        .login-button {
          margin-top: 20px;
          margin-bottom: 10px;
          width: 100%;
          height: 36px;
          background: #29d087;
          color: #fff;
          font-size: 14px;
          text-align: center;
          line-height: 36px;
          border-radius: 20px;
        }
      }

      .terms {
        display: flex;
        color: rgba(0, 0, 0, 0.3);
        margin-bottom: 22px;

        .iconfont {
          margin-right: 4px;
        }

        .papa-checkbox-checked {
          color: #29d087;
        }

        .terms-con {
          line-height: 24px;
          user-select: none;
          cursor: pointer;

          a {
            color: rgba(0, 0, 0, 0.9);

          }
        }
      }

      .other-login {
        display: flex;
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;

        .login-item {
          margin-left: 10px;
        }
      }
    }


    .copyright-box {
      position: fixed;
      bottom: 20px;
      width: 100%;
      text-align: center;
      color: rgba(0, 0, 0, 0.6) !important;
      font-size: 12px;
      z-index: 2;

      a {
        color: rgba(0, 0, 0, 0.6);
      }
      .copyright:hover{
        text-decoration: underline;
      }
    }

    a {
      text-decoration: none;
    }

    @media screen and (max-width: 1366px) {
      .pc-container {
        width: 1200px;
      }

      .bg-middle {
        img {
          width: 800px;
          top: 60%;
        }
      }
    }
  }

  .hover-btn {
    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }
  }
</style>
