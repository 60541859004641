function checkDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
      window.navigator.userAgent
  ) ?
      'h5' :
      'pc'
}

function isWeChat() {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}

export {
  checkDevice,
  isWeChat
}
