<template>
  <div class="h5-tip">
    <div class="tip-bg"></div>
    <div class="tip-content">
      <div class="tip-head">提示</div>
      <div class="content">
        请先同意 <a href="https://m.papaen.com/terms/public/agreement">《用户协议》</a>和 <a href="https://m.papaen.com/terms/public/privacy">《隐私政策》</a>
      </div>
      <div class="tip-operation">
        <div class="operation-buttons">
          <div class="operation-button button-cancel" @click="handleCancel">取消</div>
          <div class="operation-button button-submit" @click="handleSubmit">同意并登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  const emits = defineEmits(['onLogin','onCancel'])
  function handleCancel(){
    emits('onCancel')
  }
  function handleSubmit(){
    emits('onLogin')
  }


</script>

<style lang="less" scoped>
  .h5-tip{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
justify-content: center;
    .tip-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);

    }
    .tip-content{
      box-sizing: border-box;
      width: 320px;
      height: 180px;
      border-radius:8px;
      background: #fff;
      position: absolute;
      .tip-head{
        font-size: 20px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: rgba(0,0,0,0.9);
        margin: 32px 0  8px;
        text-align: center;

      }
      .content{
        margin-bottom: 33px;
        text-align: center;
        font-size: 14px;
        a{
          color: #29d087;
          text-decoration: none;
        }
      }
      .tip-operation{
        height: 56px;
        border-top: 1px solid #E5E5E5;
        font-size: 16px;
        .operation-buttons{
          display: flex;
          line-height: 56px;
          .operation-button{
            text-align: center;
            flex: 1;
          }
          .button-cancel{
            color: rgba(0,0,0,0.9);
            border-right: 1px solid #E5E5E5;
          }
          .button-submit{
            color: #29d087;
          }
        }
      }

    }
  }
</style>
