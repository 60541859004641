import { ref, Ref } from 'vue'
import { geetest, getLoginCode, getForgotCode, tencentCodeApi, tencentConfigApi } from '../api/login'
import { ELoginType, TencentCaptchaResult } from '../enums'

export function useCaptcha(username: Ref<string>, isoCode: Ref<string>, loginType: Ref<ELoginType>) {
  const firstCaptchaInput = ref<boolean>(true)
  const captcha = ref<string>('')
  const canSendCode = ref<boolean>(true)
  const verificationBtn = ref<boolean>(false)
  const captchaMessage = ref<string>('')
  const timer = ref<number>(60)
  const sendSuccess = ref<boolean>(false)
  const { VITE_TENCENT_CAPTCHA_APPID } = import.meta.env


  async function onCaptchaChange() {
    try {
      firstCaptchaInput.value = false
      captcha.value = captcha.value?.slice(0, 6) || ''
      await checkCaptcha(captcha.value)
      captchaMessage.value = ''
    } catch (e) {
      captchaMessage.value = e as string
      throw Error(e as string)
    }
  }

  function checkCaptcha(code: string | undefined) {
    return new Promise((resolve, reject) => {
      if (!code) return reject('验证码不能为空')
      if (/^\d{6}$/.test(code.toString())) {
        resolve('验证码不能为空')
      } else {
        reject('验证码格式不正确')
      }
    })
  }


  async function sendCode() {
    if (!canSendCode.value) return
    try {
      const data  = await getAidEncrypted()
      // await getGeetestConfig()
      await getTencentConfig(data)
    } catch (e) {
      console.log(e)
      canSendCode.value = true
    }
  }

  async function getGeetestConfig() {
    const { data } = await geetest()
    if (!data) return
    if (!(window as any) || !(window as any).initGeetest) {
      return false
    }
    (window as any).initGeetest(
        {
          gt: data.gt,
          challenge: data.challenge,
          offline: !data.success,
          new_captcha: true,
          product: 'bind',
          width: '300px',
          next_width: '278px'
        },
        function (captchaObj: any) {
          captchaObj
              .onReady(function () {
                captchaObj.verify()
                handleGeetest(captchaObj)
                canSendCode.value = false
              })
              .onError(function (err: any) {
                console.log(err)
                canSendCode.value = false
              })
        }
    )
  }

  function handleGeetest(captchaObj: any) {
    captchaObj.onError(function (error: any) {
      console.log(error)
    })
    captchaObj.onSuccess(async function () {
      let registerResult = captchaObj.getValidate()
      let params = {
        geetest_challenge: registerResult.geetest_challenge,
        geetest_validate: registerResult.geetest_validate,
        geetest_seccode: registerResult.geetest_seccode,
        username: username.value,
        iso_code: isoCode.value
      }
      if (loginType.value === ELoginType.captcha) {
        await getLoginCode(params)
            .then(() => {
              sendCodeSuccess()
            })
            .catch(() => {
              sendCodeFaild()
            })
      } else if (loginType.value === ELoginType.forgot) {
        await getForgotCode(params)
            .then(() => {
              sendCodeSuccess()
            })
            .catch(() => {
              sendCodeFaild()
            })
      }
    })
    captchaObj.onClose(function () {
      canSendCode.value = true

    })
  }


  async function getTencentConfig(id) {
    var captcha = new TencentCaptcha(VITE_TENCENT_CAPTCHA_APPID, callback, {
      aidEncrypted: id
    })
    // 调用方法，显示验证码
    captcha.show()
    canSendCode.value = false
  }

  async function getAidEncrypted() {
    const { data } = await tencentConfigApi()
    return data.encrypt_key
  }

  async function callback(res) {
    // 第一个参数传入回调结果，结果如下：
    // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
    // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
    // CaptchaAppId       String    验证码应用ID。
    // bizState    Any       自定义透传参数。
    // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
    // res（用户主动关闭验证码）= {ret: 2, ticket: null}
    // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
    // res（请求验证码发生错误，验证码自动返回trerror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
    if (res.ret === TencentCaptchaResult.success && !res.errorCode) {
      let params = {
        ticket: res.ticket,
        rand_str: res.randstr,
        username: username.value,
        iso_code: isoCode.value
      }
      if (loginType.value === ELoginType.captcha) {
        await tencentCodeApi(params)
            .then(() => {
              sendCodeSuccess()
            })
            .catch(() => {
              sendCodeFaild()
            })
      } else if (loginType.value === ELoginType.forgot) {
        await getForgotCode(params)
            .then(() => {
              sendCodeSuccess()
            })
            .catch(() => {
              sendCodeFaild()
            })
      }
    }
    if (res.ret === TencentCaptchaResult.close) {
      canSendCode.value = true
    }
  }

  function sendCodeSuccess() {
    ElMessage.success('验证码发送成功')
    canSendCode.value = false
    sendSuccess.value = true
    verificationBtn.value = true
    countDown()
  }

  function countDown() {
    let clock = setInterval(() => {
      timer.value--
      if (timer.value < 0) {
        clearInterval(clock)
        canSendCode.value = true
        // sendCodeBtn.value = '重新发送验证码'
        timer.value = 60
      }
    }, 1000)
  }

  function sendCodeFaild() {
    canSendCode.value = true
    verificationBtn.value = false
  }


  return {
    firstCaptchaInput,
    captcha,
    canSendCode,
    captchaMessage,
    timer,
    countDown,
    onCaptchaChange,
    getGeetestConfig,
    sendCode,
    checkCaptcha,
    sendSuccess
  }
}
