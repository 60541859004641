<template>
  <div class="c_countryCode">
    <div class="countryList-head">
      <i class="iconfont papa-chevron-left" @click="handleCloseCountryCode"></i>
      <div class="head-title">国家/地区</div>
    </div>
    <div class="country_list">
      <van-index-bar :index-list="indexList" highlight-color="#29d087">
        <van-index-bar :index-list="indexList" highlight-color="#29d087">
          <div v-for="(item, index) in countryList" :key="index">
            <van-index-anchor :index="item.alpha">
              {{ item.alpha }}
            </van-index-anchor>
            <van-cell
              v-for="country in item.subItems"
              :key="country.name"
              :title="country.name"
              :value="country.idd_code"
              value-class="vanCellValue"
              @click="setCountryCode(country)"
            ></van-cell>
          </div>
        </van-index-bar>
      </van-index-bar>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue'
  import { countryListApi } from '../api/login'
  import { ICountryItem } from '../types'

  const emits = defineEmits(['onClose', 'onChooseCode'])
  const indexList = ref([])
  const sortData = ref()
  const countryList = ref([])
  getCountryCode()

  async function getCountryCode() {
    const { data } = await countryListApi()
    formatCountryList(data)
  }

  function formatCountryList(list) {
    sortData.value = {
      热门: []
    }
    list.forEach((country) => {
      if (sortData.value[country.first_letter]) {
        sortData.value[country.first_letter].push(country)
      } else {
        sortData.value[country.first_letter] = []
        sortData.value[country.first_letter].push(country)
      }
      if (country.is_top) {
        sortData.value['热门'].push(country)
      }
    })
    formatIndex()
  }

  function formatIndex() {
    indexList.value = []
    const list: [] = []
    const data = sortData.value
    const _indexList = [
      '热门',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z'
    ]
    _indexList.forEach((item) => {
      const obj = {
        alpha: item,
        subItems: data[item]
      }
      if (data[item]) {
        list.push(obj)
        indexList.value.push(item)
      }
    })
    countryList.value = list
  }

  function handleCloseCountryCode() {
    emits('onClose')
  }

  function setCountryCode(country: ICountryItem) {
    emits('onChooseCode', { ...country })
  }
</script>

<style lang="less" scoped>
  .c_countryCode {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100vh;
    background: #f3f4f8;

    .countryList-head {
      width: 100%;
      height: 44px;
      text-align: center;
      position: relative;
      background: #fff;
      line-height: 44px;
      font-weight: bold;
      font-size: 17px;

      .iconfont {
        position: absolute;
        left: 12px;
        font-size: 20px;
      }
    }

    .papa-return {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 40px;
      font-size: 20px;
      text-align: center;
      line-height: 40px;
    }
  }

  .country_list {
    box-sizing: border-box;
    //padding: 0 20px;
    text-align: left;

    :deep(.vanCellValue) {
      padding-right: 20px;
    }
  }

</style>
