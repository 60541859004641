import { ref } from 'vue'
import { ICountryItem } from '../types'
import { CountryCode } from 'libphonenumber-js'
import { countryListApi } from '../api/login'
import { countryList as countryListData } from '../assets/data'


export function useIsoCode() {
  const iddCode = ref<number>(+86)
  const isShowIsoCodePanel = ref<boolean>(false)
  const countryList = ref<ICountryItem[]>()
  const currentIsoCode = ref<CountryCode>('CN')


  async function handleShowIsoCodePanel(status: boolean) {
    isShowIsoCodePanel.value = status
    countryList.value = await getCountryList()
  }

  async function getCountryList() {
    if (sessionStorage.countryList) {
      return JSON.parse(sessionStorage.countryList)
    } else {
      try {
        const { data } = await countryListApi()
        sessionStorage.countryList = JSON.stringify(data)
        return data
      }catch (e) {
        return countryListData
      }
    }
  }

  function handleChooseCountry(country: ICountryItem) {
    return new Promise((resolve) => {
      currentIsoCode.value = country.iso_code
      iddCode.value = country.idd_code
      isShowIsoCodePanel.value = false
      resolve(country)
    })

  }

  return {
    iddCode,
    currentIsoCode,
    countryList,
    isShowIsoCodePanel,
    handleShowIsoCodePanel,
    handleChooseCountry
  }
}
