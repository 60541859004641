<template>
  <div class="wap" id="wap">
    <div class="bg-top-left bg-img" v-if="loginType!==ELoginType.forgot && loginType !== ELoginType.bind">
      <img src="../assets/img/wap-login-bg-a@3x.png" alt="">
    </div>
    <div class="bg-bottom-left bg-img" v-if="loginType!==ELoginType.forgot && loginType !== ELoginType.bind">
      <img src="../assets/img/wap-login-bg-c@3x.png" alt="">
    </div>
    <div class="bg-bottom-right bg-img" v-if="loginType!==ELoginType.forgot && loginType !== ELoginType.bind">
      <img src="../assets/img/wap-login-bg-b@3x.png" alt="">
    </div>
    <div class="container">
      <div class="logo-wrap" v-if="loginType!==ELoginType.forgot && loginType !== ELoginType.bind">
        <div class="logo">
          <img src="../assets/img/logo-h5.png" alt="">
        </div>
        <div class="logo-text">
          <div class="top">Hi~ 欢迎来到趴趴教育</div>
          <div class="slogan">未来精英的留学备考管家</div>
        </div>
      </div>
      <div class="login-box">
        <div class="login-type" v-if="loginType !==ELoginType.forgot && loginType !== ELoginType.bind">
          <div class="type-title" :class="loginType === ELoginType.captcha?'type-title-active':''"
               @click="changeLoginType(ELoginType.captcha)">验证码登录
          </div>
          <div class="type-title" :class="loginType === ELoginType.password? 'type-title-active':''"
               @click="changeLoginType(ELoginType.password)">密码登录
          </div>
        </div>
        <div class="login-type login-type-forgot" v-else-if="loginType === ELoginType.bind">
          <div class="type-title type-forgot-active"
               @click="changeLoginType(ELoginType.password)">
            <i class="iconfont papa-chevron-left"></i>
            <div>绑定手机号</div>
          </div>
        </div>
        <div class="login-type login-type-forgot" v-else>
          <div class="type-title type-forgot-active"
               @click="changeLoginType(ELoginType.password)">
            <i class="iconfont papa-chevron-left"></i>
            <div>忘记密码</div>
          </div>
        </div>
        <div class="login-form">
          <div class="form-item">
            <div class="iso-code">
              <span class="code" @click="handleShowCountryList()">+{{ iddCode }}</span>
              <i class="iconfont papa-open" @click="handleShowCountryList()"></i>
              <div class="panel-box" v-if="isShowIsoCodePanel">
                <div class="iso-code-panel">
                  <div class="iso-code-item" v-for="country in countryList" @click="handleChooseCountry(country)">
                    <div class="country">{{ country.name }}</div>
                    <div class="country-code">+{{ country.idd_code }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide"></div>
            <div class="username">
              <input type="text"
                     maxlength="11"
                     :placeholder="usernameMessage?usernameMessage:'请输入手机号码'"
                     :class="usernameMessage?'verifyPlaceholder':''"
                     v-model.trim="username"
                     @blur="checkUsername"
              >
              <span class="error-message"
                    v-if="username.length>0&&usernameMessage!=='手机号不能为空'">{{ usernameMessage }}</span>
            </div>
            <i class="iconfont close-icon papa-cancer" v-if="username.length>0" @click="handleClear('username')"></i>
          </div>
          <div class="form-item" v-if="loginType !== ELoginType.password">
            <div class="verify-code">
              <input type="text"
                     :placeholder="captchaMessage?captchaMessage:'请输入验证码'"
                     v-model.trim="captcha"
                     :class="captchaMessage?'verifyPlaceholder':''"
                     @blur="onCaptchaChange"
              >
            </div>
            <i class="iconfont close-icon papa-cancer" v-if="captcha&&captcha.length>0"
               @click="handleClear('captcha')"></i>
            <div class="send-code" v-if="canSendCode || !sendSuccess" @click="handleSendCode">获取验证码</div>
            <div class="send-code" v-else>{{ timer }}s</div>
            <span class="error-message" v-if="captcha?.length>0 && captchaMessage !=='验证码不能为空' "
                  style="right: 110px">{{ captchaMessage }}</span>
          </div>
          <div class="form-item" v-if="loginType === ELoginType.password">
            <div class="verify-code">
              <input :type="passwordInputType"
                     :placeholder="passwordMessage?passwordMessage:'请输入密码'"
                     v-model="password"
                     :class="passwordMessage?'verifyPlaceholder':''"
                     @blur="onPasswordChange"
              >
            </div>
            <i class="iconfont close-icon papa-cancer" v-if="password.length>0" @click="handleClear('password')"></i>
            <i class="iconfont papa-showpassword" v-if="passwordInputType === 'text'"
               @click="handleChangePasswordInputType"></i>
            <i class="iconfont papa-hidepassword" v-else @click="handleChangePasswordInputType"></i>
            <div class="send-code" @click="changeLoginType(ELoginType.forgot)">忘记密码</div>
            <!--            <span class="error-message">{{ passwordMessage }}</span>-->
          </div>
          <div class="form-item" v-if="loginType === ELoginType.forgot">
            <div class="verify-code">
              <input :type="passwordInputType"
                     v-model.tr.trim="password"
                     :placeholder="passwordMessage?passwordMessage:'请输入密码'"
                     :class="passwordMessage?'verifyPlaceholder':''"
                     @blur="onPasswordChange"
              >
            </div>
            <i class="iconfont close-icon papa-cancer" v-if="password.length>0" @click="handleClear('password')"></i>
            <i class="iconfont papa-showpassword" v-if="passwordInputType === 'text'"
               @click="handleChangePasswordInputType"></i>
            <i class="iconfont papa-hidepassword " v-else @click="handleChangePasswordInputType"></i>
            <!--            <span class="error-message">{{ passwordMessage }}</span>-->
          </div>
          <div class="button-box">
            <div class="login-button" v-if="loginType === ELoginType.captcha"
                 @click="handleLogin(ELoginType.captcha)"
            >登录/注册
            </div>
            <div class="login-button" v-else-if="loginType === ELoginType.password"
                 @click="handleLogin(ELoginType.password)">登录
            </div>
            <div class="login-button" v-else-if="loginType === ELoginType.forgot"
                 @click="handleLogin(ELoginType.forgot)"
            >确认修改
            </div>
            <div class="login-button" v-else-if="loginType === ELoginType.bind"
                 @click="handleLogin(ELoginType.password)">绑定
            </div>
            <div class="login-button" @click="handleLogin(ELoginType.captcha)" v-else>登录/注册</div>
          </div>
          <div class="terms" v-if="loginType !== ELoginType.forgot && loginType !== ELoginType.bind">
            <i class="iconfont papa-checkbox-checked" @click="handleIsAgree" v-if="isAgree"></i>
            <i class="iconfont papa-checkbox-unchecked" @click="handleIsAgree" v-else></i>
            <div class="terms-con"><span @click="handleIsAgree">未注册账号的手机号，登录时将自动注册，且代表已同意</span>
              <a
                href="https://m.papaen.com/terms/public/newPrivacy/userAgreement">《用户协议》</a>和 <a
                href="https://m.papaen.com/terms/public/newPrivacy/privacyPolicy">《隐私政策》</a></div>
          </div>
          <!--          <div class="other-login" v-if="loginType !== ELoginType.forgot && loginType !== ELoginType.bind">-->
          <!--            <div class="label">其他登录方式:</div>-->
          <!--            <div class="login-item">微信</div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <h5-tip v-if="isShowTipDialog"
            @on-cancel="isShowTipDialog = false"
            @on-login="unAgreeLogin"
    ></h5-tip>
    <country-list
      v-if="isShowCountryList"
      @on-close="onCloseCountry"
      @on-choose-code="onChooseCountry"
    ></country-list>
    <van-overlay :show="isShowOverlay" @click="isShowOverlay = false">
      <div class="loading-con">
        <img src="../assets/img/load.gif" alt="">
        <div class="con-text">{{ overlayText }}</div>
      </div>
    </van-overlay>

  </div>
</template>

<script lang="ts" setup>
  import { computed, onMounted, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { ELoginType } from '../enums'
  import { useLogin } from '../hooks/useLogin'
  import { useLoginType } from '../hooks/useLoginType'
  import { useIsoCode } from '../hooks/useIsoCode'
  import { useUserFormItem } from '../hooks/useUserFormItem'
  import { useCaptcha } from '../hooks/useCaptcha'
  import { useIsAgreeTerms } from '../hooks/useIsAgreeTerms'
  import { usePassword } from '../hooks/usePassword'
  import { isWeChat } from '../util/utils'
  import H5Tip from '../components/h5Tip.vue'
  import CountryList from '../components/countryList.vue'
  import { ICountryItem } from '../types'
  import { throttle } from 'lodash'


  const isShowTipDialog = ref<boolean>(false)
  const { loginType, changeLoginType } = useLoginType()
  const {
    iddCode,
    currentIsoCode,
    countryList,
    isShowIsoCodePanel,
    handleShowIsoCodePanel,
    handleChooseCountry
  } = useIsoCode()

  const { username, usernameMessage, onUsernameChange,getIsoCode,  checkUsername } = useUserFormItem()
  const {
    captcha,
    captchaMessage,
    canSendCode,
    timer,
    countDown,
    onCaptchaChange,
    sendCode,
    sendSuccess,
    checkCaptcha
  } = useCaptcha(username, currentIsoCode, loginType)
  const {
    password,
    passwordMessage,
    passwordInputType,
    onPasswordChange,
    checkPassword,
    handleChangePasswordInputType
  } = usePassword()
  const { isAgree, handleIsAgree } = useIsAgreeTerms()
  const { loginCaptcha, loginPassword, loginForgot } = useLogin()
  const { VITE_WECHAT_APPID } = import.meta.env
  const openid = ref('')
  const choosedLoginType = ref<ELoginType>()
  const isShowCountryList = ref<boolean>(false)
  const isShowOverlay = ref<boolean>(false)


  function handleClear(type: string) {
    switch (type) {
      case 'username':
        username.value = ''
        checkUsername()
        break
      case 'password':
        password.value = ''
        break
      case 'captcha':
        captcha.value = undefined
        break
      default:
        break
    }
  }

  const route = useRoute()
  const overlayText = computed(() => {
    if (loginType.value === ELoginType.forgot) {
      return '正在修改...'
    } else {
      return '正在登录...'
    }
  })

  function unAgreeLogin() {
    isAgree.value = true
    handleLogin(loginType.value)
  }

  const isSubmitting = ref(false)

  const handleLogin = throttle(async (type)=>{
    if(isSubmitting.value) return
    isSubmitting.value = true
    try{
      await _handleLogin(type)
    }finally {
      isSubmitting.value = false
    }
  },500, {
    trailing: false
  })

  async function _handleLogin(type?: ELoginType) {
    const url = route.query.redirect_url as string
    try {
      checkUsername()
      await onUsernameChange()
      switch (type) {
        case ELoginType.captcha:
          await onCaptchaChange()
          if (!isAgree.value) {
            return isShowTipDialog.value = true
          }
          isShowOverlay.value = true
          if (openid.value) {
            await loginCaptcha({
              username: username.value,
              captcha: captcha.value as number,
              iso_code: currentIsoCode.value,
              openid: openid.value
            }, url)
          } else {
            await loginCaptcha({
              username: username.value,
              captcha: captcha.value as number,
              iso_code: currentIsoCode.value
            }, url)
          }

          break
        case
        ELoginType.password:
          await onPasswordChange()
          if (!isAgree.value) {
            return isShowTipDialog.value = true
          }
          const params = {
            username: username.value,
            password: password.value,
            iso_code: currentIsoCode.value
          }
          if (openid.value) {
            params.openid = openid.value
          }
          isShowOverlay.value = true
          await loginPassword(params, url)
          break
        case ELoginType.forgot:
          await onCaptchaChange()
          await onPasswordChange()
          isShowOverlay.value = true
          await loginForgot({
            username: username.value,
            password: password.value,
            captcha: captcha.value as number,
            iso_code: currentIsoCode.value
          }, url)
          break
      }
      isShowOverlay.value = false
      return isShowTipDialog.value = false
    } catch (e) {
      console.log(e)
      isShowOverlay.value = false
      return isShowTipDialog.value = false
    }

  }


  const  handleSendCode = throttle(()=>{
    _handleSendCode()
  },5000, {
    trailing: false
  })

  async function _handleSendCode() {
    try {
      checkUsername()
      await sendCode()
    } catch (e) {
      console.log(e)
    }
  }

  const initLoginType = ref()
  watch(() => {
    return route.query
  }, (query) => {
    initLoginType.value = parseInt(query.loginType as string) || ELoginType.captcha
    changeLoginType(initLoginType.value)
  }, { deep: true })
  onMounted(() => {
    dealWechat()
  })

  function dealWechat() {
    const isInWechat = isWeChat()
    openid.value = route.query.openid as string || ''
    if (isInWechat && (loginType.value === ELoginType.captcha || loginType.value === ELoginType.password) && openid.value === '') {
      weChatAuthorize()
    } else {
      loginType.value = ELoginType.captcha
    }
  }

  function weChatAuthorize() {
    const appId = VITE_WECHAT_APPID
    const pageFrom = route.query.redirect_url as string || 'https://m.papaen.com'
    const url = `${window.location.origin}/check?redirect_url=${pageFrom}`
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
  }

  function handleShowCountryList() {
    isShowCountryList.value = true
  }

  function onChooseCountry(country: ICountryItem) {
    handleChooseCountry(country)
    getIsoCode(country.iso_code)
    isShowCountryList.value = false

  }

  function onCloseCountry() {
    isShowCountryList.value = false
  }

</script>

<style scoped lang="less">
  .wap {
    width: 100vw;
    height: 100vh;
    position: relative;
    font-size: 12px;
    background-size: cover;
    background-color: #fff;
    color: #333;

    input, textarea {
      font-size: 14px; /* 设置输入框字体大小 */
      -webkit-text-size-adjust: 100%; /* 禁用自动缩放 */
    }

    a {
      color: rgba(0, 0, 0, 0.3);
      text-decoration: none;
    }

    .bg-img {
      position: absolute;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .bg-top-left {
      top: 0;
      left: 0;
      width: 245.5px;
    }

    .bg-bottom-left {
      bottom: 0;
      left: 0;
      width: 65px;
    }

    .bg-bottom-right {
      bottom: 0;
      right: 0;
      width: 296.5px;
    }

    .container {
      box-sizing: border-box;
      padding: 30px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      z-index: 2;
      position: relative;

      .logo-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .logo {
          width: 52px;
          height: 52px;
          font-size: 0;
          margin-right: 12px;

          img {
            width: 100%;
          }
        }

        .logo-text {
          color: rgba(0, 0, 0.9);

          .top {
            font-size: 24px;
            font-weight: bold;
          }

          .slogan {
            font-size: 14px;
          }
        }
      }

      .login-box {
        box-sizing: border-box;
        width: 100%;
        border-radius: 16px;
        z-index: 2;

        .login-type {
          user-select: none;
          display: flex;
          height: 27px;

          margin-bottom: 36px;

          .type-title {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.3);
            font-weight: 500;
            margin-right: 28px;
            cursor: pointer;
          }

          .type-title-active {
            color: rgba(0, 0, 0, 0.9);
            font-weight: bold;
            position: relative;
          }

          .type-forgot-active {
            color: rgba(0, 0, 0, 0.9);
            font-weight: bold;
            position: relative;
            display: flex;
            align-items: center;

            .papa-chevron-left {
              font-size: 22px;
              font-weight: bold;
            }
          }

          .type-title-active:after {
            content: '';
            position: absolute;
            bottom: -16px;
            left: 0;
            width: 100%;
            height: 4px;
            background: #000;
          }
        }

        .login-type-forgot {
          width: 100%;

          .type-title {
            width: 100%;
            margin-right: 0;

            & > .iconfont {
              margin-left: -18px;
            }

            & > div {
              text-align: center;
              flex: 1;
            }
          }
        }
      }

      .form-item {
        box-sizing: border-box;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #EEEEEE;
        position: relative;

        .slide {
          margin: 0 20px;
          width: 1px;
          height: 20px;
          background: #d9d9d9;
        }

        .iso-code {
          width: 48px;
          font-size: 14px;
          cursor: pointer;
          position: relative;

          .code {
            box-sizing: border-box;
            display: inline-block;
            width: 34px;
            padding-right: 6px;
          }

          .panel-box {
            position: absolute;
            top: 60px;
            width: 200px;
            height: 200px;
            overflow: auto;
            z-index: 2;
            box-shadow: rgba(0, 0, 0, 0.12) 0 0 12px 0;
            border: 1px solid #e4e7ed;
            border-radius: 8px;

            .iso-code-panel {
              background: #fff;
              display: flex;
              flex-direction: column;
              overflow: hidden;

              .iso-code-item {
                width: 100%;
                height: 45px;
                box-sizing: border-box;
                padding: 0 20px;
                display: flex;
                justify-content: space-between;

                .country-code {
                  text-align: right;
                }
              }

              .iso-code-item:hover {
                background: #f5f7fa;
              }
            }
          }
        }

        .username, .verify-code {
          flex: 1;
        }

        .iconfont {
          font-size: 12px;
          color: #000;
        }

        .close-icon {
          cursor: pointer;
          user-select: none;
          font-weight: bold;
          font-size: 14px;

        }

        .send-code {
          width: 80px;
          font-size: 14px;
          text-align: right;
          cursor: pointer;
          color: #29d087;
        }

        input {
          border: none;
          outline: none;
          width: 100%;
          height: 49px;
          background: transparent;
        }

        .papa-showpassword, .papa-hidepassword {
          font-size: 22px;
          margin-left: 10px;
        }

        .error-message {
          position: absolute;
          top: 0;
          right: 20px;
          height: 14px;
          color: red;
          font-size: 14px;
        }

        .verifyPlaceholder::placeholder {
          color: red;
        }

        input::placeholder {
          font-size: 14px;
        }

        .verifyPlaceholder::placeholder {
          color: red;
        }

        input::placeholder {
          font-size: 14px;
        }

      }

      .button-box {
        width: 100%;
        user-select: none;
        cursor: pointer;

        .login-button {
          margin-top: 20px;
          margin-bottom: 10px;
          width: 100%;
          height: 36px;
          background: #29d087;
          color: #fff;
          text-align: center;
          line-height: 36px;
          border-radius: 20px;
          font-size: 14px;
        }
      }

      .terms {
        display: flex;
        color: rgba(0, 0, 0, 0.3);
        margin-bottom: 22px;

        .iconfont {
          margin-right: 4px;
        }

        .papa-checkbox-checked {
          color: #29d087;
        }

        .terms-con {
          line-height: 24px;
          user-select: none;
          cursor: pointer;

          a {
            color: rgba(0, 0, 0, 0.9);
          }
        }
      }

      .other-login {
        display: flex;
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;

        .login-item {
          margin-left: 10px;
        }
      }
    }


    :deep(.van-overlay) {
      z-index: 999;

      .loading-con {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
          width: 90px;
        }

        .con-text {
          color: #fff;
          font-size: 14px;
        }
      }
    }

    :deep(.van-overlay) {
      z-index: 999;

      .loading-con {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
          width: 90px;
        }

        .con-text {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
</style>
