import { ref } from 'vue'
import { ELoginType } from '../enums'

export function useLoginType (){
  const loginType = ref<ELoginType>(ELoginType.captcha)
  function changeLoginType(type: ELoginType) {
    loginType.value = type
  }

  return{
    loginType,
    changeLoginType
  }
}
